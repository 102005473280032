html {
  height: 100%;
  // Needed to fix this issue
  // http://bit.ly/37APpCZ
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

body {
  height: 100%;
  overflow-y: auto;
}

// Should be removed and fixed in design system
.is-narrow {
  max-width: 700px !important;
}

.portal {
  display: grid;
  // width: 100vw;
  height: 100%;
  grid-template-columns: $navigation-width 1fr;
  grid-template-rows: $navbar-height 1fr $navbar-height;
  //IE10+
  -ms-grid-columns: $navigation-width 1fr;
  -ms-grid-rows: $navbar-height 1fr $navbar-height;
}

.portal__content {
  grid-row: 1/4;
  grid-column: 2/3;
  // required for `overflow: auto` to work
  // i.e. the tables will scroll horizontally on mobile
  min-width: 0;
}

// MEDIA QUERY
@media screen and (max-width: 1023px) {
  .portal__content {
    grid-row: 2/4;
    grid-column: 1/4;
  }
}

.splash {
  display: grid;
  grid-template-rows: 53px 1fr;
  grid-template-columns: 1fr;
  // width: 100vw;
  //IE 11
  -ms-grid-rows: 53px 1fr;
  -ms-grid-columns: 1fr;

  &__bar {
    display: flex;
    align-items: center;
    justify-content: center;
    //IE 11
    -ms-grid-row: 1;
    -ms-grid-column: 1;

    .navbar-brand {
      min-height: inherit;
    }
  }
  &__container {
    display: block;
    max-width: 450px;
    margin: 20px auto;
    //IE 11
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    > button {
      margin-top: 20px;
    }
  }
}
