/* autoprefixer grid: no-autoplace */
@import '../../../assets/uds/colors/colors.scss';
@import 'styles/_variables.scss';
@import 'styles/layout.scss';
@import 'styles/export-csv.scss';
@import 'styles/blocking-rulesets.scss';
@import 'styles/form.scss';
@import 'styles/sidepanel-animation.scss';
@import 'styles/common.scss';
@import 'styles/rtl';
// white labeling
@import 'styles/white-labeling.scss';
