@mixin sidepanel-animation($duration, $position) {
  right: $position;

  &.is-opened {
    animation: slide-ltr-in $duration forwards;
  }

  &.is-closed {
    animation: slide-ltr-out $duration forwards;
  }

  @keyframes slide-ltr-in {
    from {
      right: $position;
    }
    to {
      right: 0;
    }
  }

  @keyframes slide-ltr-out {
    from {
      right: 0;
    }
    to {
      right: $position;
    }
  }
}
