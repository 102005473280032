/* autoprefixer grid: no-autoplace */
html {
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

body {
  height: 100%;
  overflow-y: auto;
}

.is-narrow {
  max-width: 700px !important;
}

.portal {
  display: grid;
  height: 100%;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 53px 1fr 53px;
  -ms-grid-columns: 250px 1fr;
  -ms-grid-rows: 53px 1fr 53px;
}

.portal__content {
  grid-row: 1/4;
  grid-column: 2/3;
  min-width: 0;
}

@media screen and (max-width: 1023px) {
  .portal__content {
    grid-row: 2/4;
    grid-column: 1/4;
  }
}
.splash {
  display: grid;
  grid-template-rows: 53px 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 53px 1fr;
  -ms-grid-columns: 1fr;
}
.splash__bar {
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.splash__bar .navbar-brand {
  min-height: inherit;
}
.splash__container {
  display: block;
  max-width: 450px;
  margin: 20px auto;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}
.splash__container > button {
  margin-top: 20px;
}

portal-export-csv .is-wide-popup {
  right: 1rem;
  width: 30rem !important;
}

portal-list-blocking-rules .new-rules-container {
  height: calc(100vh - 186px);
}
portal-list-blocking-rules table {
  width: 100%;
  table-layout: auto;
}

portal-update-blocking-rules .info {
  margin-top: 6px;
}

portal-form-blocking-rules div.box {
  padding: 24px;
}
portal-form-blocking-rules .formName {
  width: 217px;
}
portal-form-blocking-rules .label {
  font-weight: 600;
}
portal-form-blocking-rules input {
  min-width: 170px;
}
portal-form-blocking-rules .select {
  width: 100%;
}
portal-form-blocking-rules .select select {
  width: 100%;
}
portal-form-blocking-rules .columns.is-gapless:not(:last-child) {
  margin: 0px;
}
portal-form-blocking-rules .columns.is-gapless .column {
  margin-right: 6px;
  margin-bottom: 8px;
}
portal-form-blocking-rules .help.is-danger {
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

select {
  opacity: 1;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  text-align: right;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.control.is-scrollable {
  max-height: 600px;
  overflow-y: scroll;
}

/** Needs to be corrected in Design systems */
.control.has-character-count .input {
  padding-right: 4.5em;
}

.node-content-wrapper {
  margin-left: 7px;
}

/********************************************/
portal-pagination .field-label {
  display: flex;
  align-items: center;
  flex-basis: auto;
  flex-grow: 0;
}
portal-pagination .field-body {
  flex-grow: 1;
}
portal-pagination .input {
  width: 50px;
}

th {
  white-space: nowrap;
}

td {
  white-space: nowrap;
}
td:hover {
  cursor: pointer;
}

.table-row-link {
  opacity: 0;
}
tr:hover .table-row-link, tr:focus-within .table-row-link {
  opacity: 1;
}

portal-list-transactions table td {
  max-width: 200px;
}

portal-list-transactions .message-box,
portal-list-transactions-view .message-box,
vhq-devices .message-box {
  max-width: 450px;
  margin: 0 auto !important;
  padding-top: 5rem;
}
portal-list-transactions .message-box .column:first-child,
portal-list-transactions-view .message-box .column:first-child,
vhq-devices .message-box .column:first-child {
  text-align: center;
  font-size: 2rem;
}

portal-list-users .table-row-roles {
  max-width: 400px;
}

portal-form-cartes-bancaires .radio > span {
  margin-left: 4px;
}
portal-form-cartes-bancaires table tr,
portal-form-cartes-bancaires table td,
portal-form-cartes-bancaires table th {
  cursor: auto;
}

portal-form-business-information .timeslot-field-group {
  padding-left: 29px;
}
portal-form-business-information .timeslot-control-separator {
  width: 30px;
  height: 20px;
}
portal-form-business-information .timeslot-control-separator::before {
  content: " ";
}
portal-form-business-information .timeslot-control-separator--first {
  height: 52px;
}
portal-form-business-information .timeslot-control-remove-button {
  padding-top: 8px;
}
portal-form-business-information .timeslot-control-remove-button--first {
  padding-top: 40px;
}

.table-ppc td {
  max-width: 210px;
}

.sidepanel {
  right: -600px;
  display: flex;
  flex-direction: column;
  position: fixed !important;
  top: 0;
  z-index: 99;
  width: 475px;
  height: 100%;
}
.sidepanel.is-opened {
  animation: slide-ltr-in 0.5s forwards;
}
.sidepanel.is-closed {
  animation: slide-ltr-out 0.5s forwards;
}
@keyframes slide-ltr-in {
  from {
    right: -600px;
  }
  to {
    right: 0;
  }
}
@keyframes slide-ltr-out {
  from {
    right: 0;
  }
  to {
    right: -600px;
  }
}
.sidepanel .tab-content {
  flex: 1;
  overflow-y: auto;
}

.table-container {
  z-index: 0;
}
.table-container tr td.row-message {
  text-align: center !important;
}

portal-search-filter-checkbox + portal-search-filter-checkbox > .checkbox,
portal-search-filter-radio + portal-search-filter-radio > .radio {
  margin-top: 0.5rem;
}
.dropdown-content portal-search-filter-checkbox + portal-search-filter-checkbox > .checkbox,
.dropdown-content portal-search-filter-radio + portal-search-filter-radio > .radio {
  margin-top: 1rem;
}

input[type=checkbox]:focus + span::before {
  border-color: #004aef;
  outline: 1px solid #004aef;
  outline-offset: 0;
}

input[type=radio]:focus + span::before {
  box-shadow: 0 0 0 0.125em #ccdbfc;
}

portal-transaction-detail-transaction .label,
portal-customer-detail-transaction .label,
portal-overview-detail-transaction .label,
portal-action-detail-transaction .label {
  font-weight: 600;
}

portal-filter-wrapper,
portal-add-filter-scheduler .dropdown .dropdown-menu {
  min-width: 19rem !important;
}

.is-pointer {
  cursor: pointer;
}

.has-margin-left-5 {
  margin-left: 5px;
}

.has-padding-left-17 {
  padding-left: 17px;
}

.has-padding-right-17 {
  padding-right: 17px;
}

.no-border-left {
  border-left: 0;
}

.no-border-right {
  border-right: 0;
}

.is-borderless {
  border: none;
}

.is-relative {
  position: relative;
}
.is-relative .is-dot {
  position: absolute;
  bottom: 0px;
}

.is-absolute {
  position: absolute;
}

.has-padding-top-5 {
  padding-top: 5px;
}

.has-line-through {
  text-decoration: line-through;
}

.h-full {
  height: 100%;
}

.width-0 {
  width: 0;
}

.right-0 {
  right: 0;
}

.width-full {
  width: 100%;
}

.width-is-four-fifths {
  width: 80%;
}

.width-is-one-fifth {
  width: 20%;
}

.min-width-min-content {
  min-width: min-content;
}

.min-width-max-content {
  min-width: max-content;
}

.width-max-content {
  width: max-content;
}

.min-width-50 {
  min-width: 50%;
}

.no-pointer-events {
  pointer-events: none;
}

tree-root tree-viewport {
  overflow: auto;
  min-height: 100px;
  max-height: 380px;
}

/*
  Polyfill for `:focus-visible` https://github.com/WICG/focus-visible
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

[tableRowFocusOption]:focus-visible {
  outline: none;
  border: 1px solid transparent;
}

[tableRowFocusOption]:focus {
  outline: none;
  border: none;
}

[portalKeyboardAccessibility]:focus-visible {
  outline: none;
  border: 1px solid transparent;
}

[portalKeyboardAccessibility]:focus {
  outline: none;
  border: none;
}

/* IE10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  portal-pagination .field-body {
    flex-basis: inherit;
  }
}
.has-margin-top-m10 {
  margin-top: -10px !important;
}

.has-margin-top-m2rem {
  margin-top: -2rem !important;
}

portal-currency-amount .is-static.currencies {
  pointer-events: auto;
}
portal-currency-amount .currencies select {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}

vhq-deviceprofile .table-row-devices {
  max-width: 133px;
}

portal-report-templates-list-view tr,
portal-report-templates-list-view td:hover,
portal-reporting-group-table tr,
portal-reporting-group-table td:hover {
  cursor: default;
}

portal-generated-reports-table tr,
portal-generated-reports-table td {
  cursor: default;
  max-width: 280px;
}
portal-generated-reports-table tr button,
portal-generated-reports-table td button {
  max-height: 24px;
}
portal-generated-reports-table tr .has-text-truncate,
portal-generated-reports-table td .has-text-truncate {
  max-width: 95%;
}

portal-toast {
  position: absolute;
  max-width: 450px;
  bottom: 2rem;
  z-index: 99;
}

/**
Disable e.g Tags (a,div,...)
 */
.is-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.4;
}

.button.is-transparent:focus-visible,
.js-focus-visible .button.is-transparent.focus-visible {
  border-width: 2px;
}

.button.additional-filter-button:focus-visible,
.js-focus-visible .button.additional-filter-button.focus-visible {
  border-radius: 4px;
}

.button.additional-filter-button:hover,
.button-clear-filter:hover {
  text-decoration: underline;
}

vhq-deviceprofile > * portal-search-filter-radio[radioName=dateType],
vhq-devices > * portal-search-filter-radio[radioName=dateType] {
  display: none;
}

.has-text-word-break {
  word-break: break-word;
}

/**
 Table Receipt Transaction documents
*/
.has-text-truncate-width {
  max-width: 200px;
}

.has-max-height-250 {
  max-height: 250px !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-name {
  max-width: 13rem;
}

.table.line-items-table {
  border-radius: 0;
}
.table.line-items-table,
.table.line-items-table tbody tr,
.table.line-items-table thead tr,
.table.line-items-table thead tr th,
.table.line-items-table tbody tr td {
  border-radius: 0;
  background: inherit;
}
.table.line-items-table tr:hover,
.table.line-items-table tr:hover td,
.table.line-items-table td:hover {
  cursor: auto;
  background: inherit;
}

.wrap-content {
  overflow-wrap: break-word;
}

.button:not([disabled]):not(.is-static):not(.close-detail):not(.is-danger).is-multiple-filters .filters-in-line {
  padding-right: 0;
  padding-left: 0;
  text-align: left;
}
.button:not([disabled]):not(.is-static):not(.close-detail):not(.is-danger).is-multiple-filters .filters-in-line .filter-text {
  display: inline-block;
  margin-right: 0.3rem;
}
.button:not([disabled]):not(.is-static):not(.close-detail):not(.is-danger).is-multiple-filters .filters-in-line .counter-label span {
  border-radius: 26px;
  padding: 4px 13px;
  width: 1em;
  height: 1em;
}

.payment-link-form .accordion.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.payment-link-form .accordion .ng-select .ng-dropdown-panel {
  position: relative !important;
  display: inherit !important;
}
.payment-link-form .accordion .accordion-header:focus-visible:not(.is-invalid) {
  border: 0.1em solid #004aef !important;
  outline: 0.1em solid #004aef !important;
}
.payment-link-form .accordion .accordion-header.is-invalid:focus-visible {
  border: 0.1em solid #d3135d !important;
  outline: 0.1em solid #d3135d !important;
}
.payment-link-form .accordion .accordion-header label.checkbox {
  padding-left: 19px;
}
.payment-link-form .accordion .accordion-header label.checkbox:focus-within {
  border: 0.1em solid !important;
  outline: 0.1em solid !important;
}
.payment-link-form .accordion .accordion-header label.checkbox span:empty {
  padding: 0;
}
.payment-link-form .image-container {
  display: flex;
  justify-content: end;
}
.payment-link-form .tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-right: 10px;
}
.payment-link-form .tooltip-custom {
  visibility: hidden;
  width: max-content;
  position: absolute;
  padding: 10px;
  top: -50px;
  padding-bottom: 0px;
  padding-right: 0px;
  margin-left: 0px;
  transform: translate(-50%);
}
.payment-link-form .tooltip-container:hover .tooltip-custom {
  visibility: visible;
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.max-width-full {
  max-width: 100%;
}

.table .portal-basic-table-header .is-sortable {
  min-width: calc(100% + 23px);
}

.tabs ul {
  margin: 0.1rem auto 0.2rem 0.1rem !important;
}

.has-padding-right-5px {
  padding-right: 5px;
}

.width-50 {
  width: 50% !important;
}

.white-space-pre {
  white-space: pre !important;
}

.has-background-highlighted {
  background-color: #ccdbfc;
}

@media screen and (min-width: 600px) {
  portal-toast {
    right: 2rem;
  }
}
.is-overflow {
  overflow: visible !important;
}

portal-processor-settlement-detail tr,
portal-processor-settlement-detail td:hover,
portal-settlement-detail tr,
portal-settlement-detail td:hover {
  cursor: default;
}

@media screen and (min-width: 768px) {
  .modal-content {
    min-width: max-content;
  }
}
@media screen and (max-width: 480px) {
  .modal .title {
    padding-right: 0 !important;
  }
}
@media (max-width: 767px) {
  .xs-full-width {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .home-product-card {
    min-height: auto !important;
  }
}
.select-control .ng-select-single .ng-select-container .ng-value-container {
  padding-right: 10px;
}

.has-break-all {
  word-break: break-all;
}

portal-merchant-choice-routing .toggle-disabled input[type=checkbox]:disabled + span::before {
  background-color: #004aef !important;
}

[dir=rtl] portal-date-control .ngb-dp-arrow-btn,
[dir=rtl] portal-date-picker .ngb-dp-arrow-btn {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.white-labeled .title {
  color: var(--navbar-item-color) !important;
}
.white-labeled vui-top-common-navbar {
  display: block;
  background-color: var(--navbar-background-color) !important;
}
.white-labeled authentication-root .auth-navbar-background {
  background-color: var(--navbar-background-color) !important;
}
.white-labeled .hero.is-rebrand {
  background: 0 !important;
  height: 153px;
}
.white-labeled .hero.is-rebrand .hero-body {
  padding-top: 4.5rem;
}
.white-labeled .hero.is-rebrand .hero-body .hero-icon {
  display: none;
}
.white-labeled .hero.is-rebrand .hero-body .container .title {
  color: var(--navbar-item-color) !important;
  margin-left: 0 !important;
}
.white-labeled .developer-portal-section {
  display: none;
}
.white-labeled .payment-method-button .has-border-primary {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .payment-method-button .has-text-primary {
  color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .card-number-tag.has-background-primary {
  background-color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .has-background-info-light {
  background-color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .accordions .accordion .accordion-header {
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled .accordions .accordion.is-active .accordion-header {
  background-color: var(--brand-hover-light-background) !important;
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .accordions .accordion.is-active .accordion-header,
.white-labeled .has-border-primary {
  border: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .payment-link-form .accordion .accordion-header:focus-visible:not(.is-invalid) {
  border: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
  outline: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .payment-link-form .accordion .accordion-header.is-invalid:focus-visible {
  border: 0.1em solid #d3135d !important;
  outline: 0.1em solid #d3135d !important;
}
.white-labeled .payment-link-form .accordion .accordion-header label.checkbox:focus-within {
  border: 1px solid !important;
  outline: 1px solid !important;
}
.white-labeled .payment-link-form .accordion .accordion-header label.checkbox span:empty {
  padding: 0;
}
.white-labeled .navbar,
.white-labeled .navbar.is-rebrand {
  background-color: var(--navbar-background-color) !important;
}
.white-labeled .navbar .navbar-dropdown,
.white-labeled .navbar.is-rebrand .navbar-dropdown {
  background-color: var(--navbar-dropdown-background-color);
}
.white-labeled .navbar .navbar-menu,
.white-labeled .navbar.is-rebrand .navbar-menu {
  background-color: var(--navbar-background-color);
}
@media (max-width: 1023px) {
  .white-labeled .navbar .navbar-menu,
.white-labeled .navbar.is-rebrand .navbar-menu {
    background-color: var(--navbar-dropdown-background-color);
  }
}
.white-labeled .navbar a.navbar-item:focus, .white-labeled .navbar a.navbar-item:hover,
.white-labeled .navbar.is-rebrand a.navbar-item:focus,
.white-labeled .navbar.is-rebrand a.navbar-item:hover {
  background-color: var(--navbar-dropdown-item-focus-hover-background-color);
}
.white-labeled .navbar a.navbar-item.is-active,
.white-labeled .navbar.is-rebrand a.navbar-item.is-active {
  color: var(--navbar-dropdown-item-active-color) !important;
  background-color: var(--navbar-dropdown-item-active-background-color) !important;
}
.white-labeled .navbar .navbar-menu-item .navbar-link:focus, .white-labeled .navbar .navbar-menu-item .navbar-link:hover, .white-labeled .navbar .navbar-menu-item .navbar-link.is-active,
.white-labeled .navbar.is-rebrand .navbar-menu-item .navbar-link:focus,
.white-labeled .navbar.is-rebrand .navbar-menu-item .navbar-link:hover,
.white-labeled .navbar.is-rebrand .navbar-menu-item .navbar-link.is-active {
  background-color: var(--navbar-item-link-focus-hover-active-background-color);
  color: var(--navbar-item-link-focus-hover-active-color);
}
.white-labeled .navbar .navbar-start > .navbar-item,
.white-labeled .navbar .navbar-start .navbar-link,
.white-labeled .navbar .navbar-end > .navbar-item,
.white-labeled .navbar .navbar-end .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-start > .navbar-item,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-end > .navbar-item,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link {
  color: var(--navbar-item-color) !important;
}
.white-labeled .navbar .navbar-start > a.navbar-item:focus, .white-labeled .navbar .navbar-start > a.navbar-item:hover, .white-labeled .navbar .navbar-start > a.navbar-item.is-active,
.white-labeled .navbar .navbar-start .navbar-link:focus,
.white-labeled .navbar .navbar-start .navbar-link:hover,
.white-labeled .navbar .navbar-start .navbar-link.is-active,
.white-labeled .navbar .navbar-end > a.navbar-item:focus,
.white-labeled .navbar .navbar-end > a.navbar-item:hover,
.white-labeled .navbar .navbar-end > a.navbar-item.is-active,
.white-labeled .navbar .navbar-end .navbar-link:focus,
.white-labeled .navbar .navbar-end .navbar-link:hover,
.white-labeled .navbar .navbar-end .navbar-link.is-active,
.white-labeled .navbar.is-rebrand .navbar-start > a.navbar-item:focus,
.white-labeled .navbar.is-rebrand .navbar-start > a.navbar-item:hover,
.white-labeled .navbar.is-rebrand .navbar-start > a.navbar-item.is-active,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link:focus,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link:hover,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link.is-active,
.white-labeled .navbar.is-rebrand .navbar-end > a.navbar-item:focus,
.white-labeled .navbar.is-rebrand .navbar-end > a.navbar-item:hover,
.white-labeled .navbar.is-rebrand .navbar-end > a.navbar-item.is-active,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link:focus,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link:hover,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link.is-active {
  background-color: var(--navbar-background-color) !important;
  color: var(--navbar-item-color) !important;
}
.white-labeled .navbar .navbar-start .navbar-link::after,
.white-labeled .navbar .navbar-end .navbar-link::after,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link::after,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link::after {
  border-color: var(--navbar-item-link-border-color);
}
.white-labeled .navbar .navbar-item.has-dropdown:focus .navbar-link,
.white-labeled .navbar .navbar-item.has-dropdown:hover .navbar-link,
.white-labeled .navbar .navbar-item.has-dropdown.is-active .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown:focus .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown:hover .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: var(--navbar-background-color);
  color: var(--navbar-item-link-color);
}
.white-labeled .navbar .navbar-item.has-dropdown:focus .navbar-link span,
.white-labeled .navbar .navbar-item.has-dropdown:hover .navbar-link span,
.white-labeled .navbar .navbar-item.has-dropdown.is-active .navbar-link span,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown:focus .navbar-link span,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown:hover .navbar-link span,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown.is-active .navbar-link span {
  border-bottom: 1px solid var(--navbar-item-link-color) !important;
}
.white-labeled .navbar-item,
.white-labeled .navbar-link {
  color: var(--navbar-item-color);
}
.white-labeled a.navbar-item,
.white-labeled .navbar-link {
  color: var(--navbar-item-color) !important;
  cursor: pointer;
}
.white-labeled a.navbar-item:focus, .white-labeled a.navbar-item:focus-within, .white-labeled a.navbar-item:hover, .white-labeled a.navbar-item.is-active,
.white-labeled .navbar-link:focus,
.white-labeled .navbar-link:focus-within,
.white-labeled .navbar-link:hover,
.white-labeled .navbar-link.is-active {
  background-color: var(--navbar-item-hover-background-color);
  color: var(--navbar-item-hover-color);
}
.white-labeled .navbar-item:hover .navbar-item-inner, .white-labeled .navbar-item.is-active .navbar-item-inner {
  background: var(--navbar-item-hover-background-color);
  color: var(--navbar-item-inner-hover-color);
}
.white-labeled .navbar-item:hover .navbar-item-inner .arrow, .white-labeled .navbar-item.is-active .navbar-item-inner .arrow {
  color: var(--navbar-item-inner-hover-color);
}
.white-labeled .navbar-item-inner {
  color: var(--navbar-item-color);
}
.white-labeled .navbar-item.has-circle .is-active .circle,
.white-labeled .navbar-item.has-circle .is-active .arrow {
  color: var(--navbar-item-color);
}
.white-labeled .navbar-item.has-circle .navbar-link:hover .circle,
.white-labeled .navbar-item.has-circle .navbar-link:hover .arrow, .white-labeled .navbar-item.has-circle .navbar-link.is-active .circle,
.white-labeled .navbar-item.has-circle .navbar-link.is-active .arrow {
  color: var(--navbar-item-color);
}
.white-labeled .navbar-link:not(.is-arrowless) .arrow {
  color: var(--navbar-item-color);
}
.white-labeled .navbar-brand a.navbar-item:focus, .white-labeled .navbar-brand a.navbar-item:hover {
  background-color: transparent;
}
.white-labeled .navbar-burger span {
  color: var(--navbar-item-color) !important;
}
.white-labeled .splash__bar {
  background-color: var(--navbar-background-color);
}
.white-labeled footer.footer {
  background-color: var(--navbar-background-color);
}
.white-labeled footer.footer .footer-bar {
  color: var(--navbar-item-color);
}
.white-labeled footer.footer .footer-items {
  color: var(--navbar-item-color);
}
.white-labeled .term {
  color: var(--primary-button-active-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail):not(.is-chip) {
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail):focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail):hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-hovered {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail):active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-outlined-active-border-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-text {
  color: var(--primary-button-outlined-text-color);
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-text:focus-visible {
  border: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary {
  color: var(--primary-button-text-color) !important;
  background-color: var(--primary-button-background-color);
  text-decoration-color: var(--primary-button-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-focused {
  color: var(--primary-button-hover-focus-text-color) !important;
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-focused {
  border-color: var(--brand-outlined-text) !important;
  outline: 1px solid var(--brand-outlined-text) !important;
  outline-offset: 0 !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined {
  background-color: var(--primary-button-outlined-background-color);
  border-color: var(--primary-button-outlined-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined.is-focused {
  color: var(--primary-button-outlined-hover-focus-text-color) !important;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-text {
  color: var(--primary-button-outlined-text-color);
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-focused {
  color: var(--primary-button-hover-focus-text-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-focused {
  border-color: var(--primary-button-focus-border-color) !important;
  outline: var(--primary-button-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined {
  background-color: inherit;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-active-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined.is-focused {
  color: var(--primary-button-text-color);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-text {
  color: var(--primary-button-active-border-color) !important;
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary {
  color: var(--primary-button-text-color) !important;
  background-color: var(--primary-button-background-color);
  text-decoration-color: var(--primary-button-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-outlined-active-border-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined {
  background-color: var(--primary-button-outlined-background-color);
  border-color: var(--primary-button-outlined-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-focused {
  color: var(--primary-button-outlined-hover-focus-text-color) !important;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-text {
  color: var(--primary-button-outlined-text-color) !important;
  box-shadow: none;
  border-color: transparent !important;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  color: var(--primary-button-hover-focus-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-focused {
  color: var(--primary-button-hover-focus-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined {
  background-color: inherit;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-active-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-focused {
  color: var(--primary-button-text-color);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-text {
  color: var(--primary-button-active-border-color) !important;
  box-shadow: none;
  border-color: transparent !important;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-focused, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-applied {
  color: var(--primary-button-active-border-color);
  border-color: var(--primary-button-active-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-focused, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-applied {
  background-color: var(--primary-button-chip-active-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:focus.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-focused.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-active.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:active.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-applied.is-active {
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).additional-filter-button, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).button-clear-filter {
  color: var(--primary-button-outlined-text-color);
  text-decoration-color: var(--primary-button-text-color);
  box-shadow: none;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).icon {
  color: var(--primary-button-outlined-text-color);
  border-color: transparent;
  box-shadow: none;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-small {
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail):not(.is-chip) {
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail):focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail):active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-outlined-active-border-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-text {
  color: var(--primary-button-outlined-text-color);
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-outlined {
  background-color: var(--primary-button-outlined-background-color);
  border-color: var(--primary-button-outlined-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-outlined:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-text {
  color: var(--primary-button-outlined-text-color);
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-focused {
  color: var(--primary-button-hover-focus-text-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:focus-visible, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-focused {
  border-color: var(--primary-button-focus-border-color) !important;
  outline: var(--primary-button-focus-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined {
  background-color: inherit;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-active-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined.is-focused {
  color: var(--primary-button-text-color);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-text {
  color: var(--primary-button-active-border-color) !important;
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary {
  color: var(--primary-button-text-color) !important;
  background-color: var(--primary-button-background-color);
  text-decoration-color: var(--primary-button-text-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:focus-visible, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:focus-visible, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-outlined-active-border-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined {
  background-color: var(--primary-button-outlined-background-color);
  border-color: var(--primary-button-outlined-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-focused {
  color: var(--primary-button-outlined-hover-focus-text-color) !important;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-text {
  color: var(--primary-button-outlined-text-color) !important;
  box-shadow: none;
  border-color: transparent !important;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:focus-visible, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-focused {
  color: var(--primary-button-hover-focus-text-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined {
  background-color: inherit;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-active-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-focused {
  color: var(--primary-button-text-color);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-text {
  color: var(--primary-button-active-border-color) !important;
  box-shadow: none;
  border-color: transparent !important;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-applied {
  color: var(--primary-button-active-border-color);
  border-color: var(--primary-button-active-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-applied {
  background-color: var(--primary-button-chip-active-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-active.is-active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip:active.is-active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-applied.is-active {
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).additional-filter-button, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).button-clear-filter {
  color: var(--primary-button-outlined-text-color);
  text-decoration-color: var(--primary-button-text-color);
  box-shadow: none;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).icon {
  color: var(--primary-button-outlined-text-color);
  border-color: transparent;
  box-shadow: none;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-small {
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button.is-transparent {
  color: var(--primary-button-text-color);
}
.white-labeled .button.is-transparent.close-detail {
  color: var(--primary-button-active-border-color) !important;
}
.white-labeled .button.is-transparent.close-detail:hover,
.white-labeled .button:hover {
  outline: 1px solid var(--primary-button-active-border-color) !important;
  color: var(--primary-button-outlined-text-color);
}
.white-labeled vui-kpi .button:hover {
  box-shadow: none !important;
}
.white-labeled .mat-chip-list mat-chip.mat-chip {
  color: var(--primary-button-active-border-color);
  border-color: var(--primary-button-active-border-color);
  background-color: var(--primary-button-chip-active-background-color);
}
.white-labeled .dropdown-content {
  border-color: var(--dropdown-content-border-color);
  background-color: var(--dropdown-content-background-color);
  box-shadow: 0px 4px 14px var(--dropdown-content-box-shadow-color);
}
.white-labeled .dropdown-menu.add-filter-menu a.dropdown-item:focus {
  background-color: var(--dropdown-item-filter-focus-background-color);
}
.white-labeled a.dropdown-item {
  color: var(--dropdown-item-color);
}
.white-labeled a.dropdown-item:hover, .white-labeled a.dropdown-item:focus, .white-labeled a.dropdown-item:active {
  color: var(--dropdown-item-color);
  background-color: var(--dropdown-item-hover-background-color);
}
.white-labeled a.dropdown-item.is-active {
  color: var(--dropdown-item-active-color) !important;
  background-color: var(--dropdown-item-active-background-color) !important;
}
.white-labeled mat-tree .tree-item:hover,
.white-labeled mat-tree mat-tree-node:hover {
  background-color: var(--dropdown-item-hover-background-color);
}
.white-labeled .input:active,
.white-labeled .input:focus,
.white-labeled .is-active.input,
.white-labeled .is-active.textarea,
.white-labeled .is-focused.input,
.white-labeled .is-focused.textarea,
.white-labeled .select select.is-active,
.white-labeled .select select.is-focused,
.white-labeled .select select:active,
.white-labeled .select select:focus,
.white-labeled .textarea:active,
.white-labeled .textarea:focus {
  border-color: var(--control-active-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color);
}
.white-labeled input[type=radio]:checked + span::before {
  border-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=radio]:checked + span::after {
  background-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=radio]:focus + span::before {
  border-color: var(--control-active-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color) !important;
}
.white-labeled input[type=radio]:focus + span::after {
  background-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=checkbox]:checked + span::before,
.white-labeled input[type=checkbox]:checked + label::before,
.white-labeled input[type=checkbox]:indeterminate + span::before,
.white-labeled input[type=checkbox]:indeterminate + label::before {
  border-color: var(--control-active-focus-border-color) !important;
  background-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=checkbox]:focus + span::before,
.white-labeled input[type=checkbox]:focus + label::before {
  outline: 1px solid var(--control-active-focus-border-color) !important;
  border-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=text].copy-input {
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled a,
.white-labeled a:hover,
.white-labeled a:focus,
.white-labeled a:visited {
  color: var(--link-color) !important;
}
.white-labeled a.has-text-dark {
  color: var(--navbar-item-color) !important;
}
.white-labeled i.has-text-link {
  border-color: var(--link-color);
}
.white-labeled .has-text-link,
.white-labeled .has-text-link:hover,
.white-labeled .has-text-link:active {
  color: var(--link-color) !important;
}
.white-labeled .steps:not(.is-hollow) .steps-marker:not(.is-hollow),
.white-labeled .steps:not(.is-hollow) .steps-segment::after,
.white-labeled .steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow) {
  background-color: var(--step-background-color) !important;
}
.white-labeled .steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment::after,
.white-labeled .steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow),
.white-labeled .steps:not(.is-hollow) .steps-segment.is-active::after {
  background-color: var(--step-default-background-color) !important;
}
.white-labeled .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, .white-labeled .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: var(--ng-select-background-color) !important;
}
.white-labeled .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--ng-select-background-color);
}
.white-labeled .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right-color: var(--ng-select-tag-border-color);
}
.white-labeled .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:hover {
  background-color: var(--ng-select-background-color);
}
.white-labeled .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: var(--control-active-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color);
}
.white-labeled ngb-datepicker .ngb-dp-arrow-btn {
  color: var(--ng-datepicker-weekdays-arrows);
}
.white-labeled ngb-datepicker .ngb-dp-weekdays .ngb-dp-weekday {
  color: var(--brand-background);
}
.white-labeled ngb-datepicker .disabled .custom-day {
  color: var(--brand-background);
  opacity: 0.3;
}
.white-labeled ngb-datepicker .custom-day:hover, .white-labeled ngb-datepicker .custom-day.is-hovered, .white-labeled ngb-datepicker .custom-day.first.range, .white-labeled ngb-datepicker .custom-day.last.range {
  background-color: var(--ng-datepicker-custom-day-background-color);
}
.white-labeled ngb-datepicker .custom-day.faded {
  background-color: var(--ng-datepicker-range-background-color);
}
.white-labeled .tabs li.is-active a,
.white-labeled .sidepanel.sidepanel--theme-winter .tabs li.is-active a {
  color: var(--tab-color);
  border-bottom-color: var(--tab-border-color);
}
.white-labeled .table.is-hoverable tbody > tr:not(.is-selected):focus-within,
.white-labeled .table.is-hoverable tbody > tr:not(.is-selected):hover {
  background-color: var(--table-background-color);
}
.white-labeled .node-content-wrapper:hover {
  box-shadow: none;
  background-color: var(--tree-wrapper-hover-background-color);
}
.white-labeled .node-content-wrapper.node-content-wrapper-focused:hover, .white-labeled .node-content-wrapper.node-content-wrapper-focused {
  box-shadow: none;
  background-color: var(--tree-wrapper-active-background-color);
}
.white-labeled tree-root .node-content-wrapper-active {
  background: none;
  box-shadow: none;
}
.white-labeled tree-root .node-content-wrapper-active:hover {
  background: none;
}
.white-labeled tree-node-wrapper input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid var(--brand-outlined-border);
  color: #ffffff;
}
.white-labeled tree-node-wrapper input[type=checkbox]:disabled {
  border-color: #cccccc;
  opacity: 1;
  cursor: default;
}
.white-labeled tree-node-wrapper input[type=checkbox]:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  border-color: var(--control-active-focus-border-color);
  outline: 1px solid var(--control-active-focus-border-color);
}
.white-labeled tree-node-wrapper input[type=checkbox]:indeterminate:after {
  background-color: var(--control-active-focus-border-color);
}
.white-labeled tree-node-wrapper input[type=checkbox]:checked:before {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-color: var(--control-active-focus-border-color) !important;
  background-color: var(--control-active-focus-border-color) !important;
}
.white-labeled tree-node-wrapper input[type=checkbox]:checked:after {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: var(--control-active-focus-border-color);
  border-color: var(--brand-outlined-border);
}
.white-labeled .load-more-container .spinner-container .spinner::before {
  border: 0.25rem solid var(--control-active-focus-border-color);
  border-top-color: var(--control-active-focus-border-color);
  border-top-style: solid;
  border-top-width: 0.25rem;
  border-right-color: transparent;
  border-right-style: solid;
  border-right-width: 0.25rem;
  border-bottom-color: var(--control-active-focus-border-color);
  border-bottom-style: solid;
  border-bottom-width: 0.25rem;
  border-left-color: var(--control-active-focus-border-color);
  border-left-style: solid;
  border-left-width: 0.25rem;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}
.white-labeled footer {
  display: none;
}
.white-labeled .notification.is-info {
  background-color: var(--brand-hover-light-background);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  color: #262626 !important;
}
.white-labeled .home-product-card .home-product-card-right .no-kpi-background {
  border: 1px solid var(--home-card-kpi-border-color);
  border-radius: 32px;
}
.white-labeled .has-background-highlighted {
  background-color: var(--brand-hover-light-background);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
}

.button.is-text:active,
.button.is-text:focus,
.button.is-text:hover,
.button.is-text:focus-visible {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
}

.button.is-primary.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-outlined {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  color: var(--primary-button-outlined-hover-focus-border-color) !important;
}

@media screen and (max-width: 1023px) {
  .white-labeled .hero.is-rebrand {
    background: 0 !important;
    height: auto;
    margin-bottom: inherit;
    top: inherit;
    max-height: inherit;
    padding-top: inherit;
  }
  .white-labeled .hero.is-rebrand .hero-body {
    padding: 1.5rem 0 0 1.5rem;
  }
  .white-labeled .hero.is-rebrand .hero-body .title {
    font-size: 2rem;
  }
}
.white-labeled {
  font-family: var(--font, "Noto Sans"), sans-serif;
}
.white-labeled body,
.white-labeled button,
.white-labeled input,
.white-labeled optgroup,
.white-labeled select,
.white-labeled textarea,
.white-labeled .tooltip::before,
.white-labeled .navbar {
  font-family: var(--font, "Noto Sans"), sans-serif;
}