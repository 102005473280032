select {
  opacity: 1;
}

input[type='number'] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  text-align: right;
}

.control.is-scrollable {
  max-height: 600px;
  overflow-y: scroll;
}

/** Needs to be corrected in Design systems */
.control.has-character-count .input {
  padding-right: 4.5em;
}

.node-content-wrapper {
  margin-left: 7px;
}
/********************************************/
