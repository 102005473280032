portal-pagination {
  .field-label {
    display: flex;
    align-items: center;
    flex-basis: auto;
    flex-grow: 0;
  }

  .field-body {
    flex-grow: 1;
  }

  .input {
    width: 50px;
  }
}

th {
  white-space: nowrap;
}

td {
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
}

.table-row-link {
  opacity: 0;

  tr:hover &,
  tr:focus-within & {
    opacity: 1;
  }
}

portal-list-transactions {
  table {
    td {
      // `max-width` of cells, where a `.has-text-truncate` child should truncate display an ellipsis.
      // 200px is used because this is the required width necessary to display the Creation Date column,
      // which shouldn't truncate
      max-width: 200px;
    }
  }
}

portal-list-transactions,
portal-list-transactions-view,
vhq-devices {
  .message-box {
    max-width: 450px;
    margin: 0 auto !important;
    padding-top: 5rem;

    .column:first-child {
      text-align: center;
      font-size: 2rem;
    }
  }
}

portal-list-users {
  .table-row-roles {
    max-width: 400px;
  }
}

portal-form-cartes-bancaires {
  .radio > span {
    margin-left: 4px;
  }

  table {
    tr,
    td,
    th {
      cursor: auto;
    }
  }
}

portal-form-business-information {
  .timeslot-field-group {
    padding-left: 29px;
  }

  .timeslot-control-separator {
    width: 30px;
    height: 20px;

    &::before {
      content: ' ';
    }
  }

  .timeslot-control-separator--first {
    height: 52px;
  }

  .timeslot-control-remove-button {
    padding-top: 8px;
  }

  .timeslot-control-remove-button--first {
    padding-top: 40px;
  }
}

.table-ppc {
  td {
    max-width: 210px;
  }
}

.sidepanel {
  @include sidepanel-animation(0.5s, -600px);
  display: flex;
  flex-direction: column;
  position: fixed !important;
  top: 0;
  z-index: 99;
  width: 475px;
  height: 100%;

  .tab-content {
    flex: 1;
    overflow-y: auto;
  }
}

.table-container {
  z-index: 0;
  tr {
    td {
      &.row-message {
        text-align: center !important;
      }
    }
  }
}

// Apply the same spacing as `.checkbox + .checkbox` and `.radio + .radio`
portal-search-filter-checkbox + portal-search-filter-checkbox > .checkbox,
portal-search-filter-radio + portal-search-filter-radio > .radio {
  margin-top: 0.5rem;

  // Apply bigger spacing between .checkbox and .radio used inside a .dropdown-content
  .dropdown-content & {
    margin-top: 1rem;
  }
}

// TODO: [Update UDS] : Highlight selected checkbox when focused
input[type='checkbox']:focus + span {
  &::before {
    border-color: $vf-primary-100;
    outline: 1px solid $vf-primary-100;
    outline-offset: 0;
  }
}

input[type='radio']:focus + span {
  &::before {
    box-shadow: 0 0 0 0.125em $vf-primary-20;
  }
}

portal-transaction-detail-transaction,
portal-customer-detail-transaction,
portal-overview-detail-transaction,
portal-action-detail-transaction {
  .label {
    font-weight: 600;
  }
}

portal-filter-wrapper,
portal-add-filter-scheduler .dropdown .dropdown-menu {
  $dropdown-menu-min-width-large: 19rem !important;

  min-width: $dropdown-menu-min-width-large;
}

// Utilities
.is-pointer {
  cursor: pointer;
}

.has-margin-left-5 {
  margin-left: 5px;
}

.has-padding-left-17 {
  padding-left: 17px;
}

.has-padding-right-17 {
  padding-right: 17px;
}

.no-border-left {
  border-left: 0;
}

.no-border-right {
  border-right: 0;
}

.is-borderless {
  border: none;
}

.is-relative {
  position: relative;

  .is-dot {
    position: absolute;
    bottom: 0px;
  }
}

.is-absolute {
  position: absolute;
}

.has-padding-top-5 {
  padding-top: 5px;
}

.has-line-through {
  text-decoration: line-through;
}

.h-full {
  height: 100%;
}

.width-0 {
  width: 0;
}

.right-0 {
  right: 0;
}

.width-full {
  width: 100%;
}

.width-is-four-fifths {
  width: 80%;
}

.width-is-one-fifth {
  width: 20%;
}

.min-width-min-content {
  min-width: min-content;
}

.min-width-max-content {
  min-width: max-content;
}

.width-max-content {
  width: max-content;
}

.min-width-50 {
  min-width: 50%;
}

.no-pointer-events {
  pointer-events: none;
}

// 3rd party angular-tree-cxomponent
tree-root tree-viewport {
  overflow: auto;
  min-height: 100px;
  max-height: 380px;
}

/*
  Polyfill for `:focus-visible` https://github.com/WICG/focus-visible
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

[tableRowFocusOption]:focus-visible {
  outline: none;
  border: 1px solid transparent;
}
[tableRowFocusOption]:focus {
  outline: none;
  border: none;
}
[portalKeyboardAccessibility]:focus-visible {
  outline: none;
  border: 1px solid transparent;
}
[portalKeyboardAccessibility]:focus {
  outline: none;
  border: none;
}

/* IE10+ */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  portal-pagination {
    .field-body {
      flex-basis: inherit;
    }
  }
}

// Virtual Terminal

.has-margin-top-m10 {
  margin-top: -10px !important;
}

.has-margin-top-m2rem {
  margin-top: -2rem !important;
}

portal-currency-amount {
  .is-static {
    &.currencies {
      pointer-events: auto;
    }
  }

  .currencies select {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
  }
}

vhq-deviceprofile {
  .table-row-devices {
    max-width: 133px;
  }
}

//manage-template

portal-report-templates-list-view,
portal-reporting-group-table {
  tr,
  td:hover {
    cursor: default;
  }
}

// report engine
portal-generated-reports-table {
  tr,
  td {
    cursor: default;
    max-width: 280px;

    button {
      max-height: 24px;
    }
    .has-text-truncate {
      max-width: 95%;
    }
  }
}

// Portal toast styles

portal-toast {
  position: absolute;
  max-width: 450px;
  bottom: 2rem;
  z-index: 99;
}

/**
Disable e.g Tags (a,div,...)
 */
.is-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.4;
}

.button.is-transparent:focus-visible,
.js-focus-visible .button.is-transparent.focus-visible {
  border-width: 2px;
}

.button.additional-filter-button:focus-visible,
.js-focus-visible .button.additional-filter-button.focus-visible {
  border-radius: 4px;
}

.button.additional-filter-button:hover,
.button-clear-filter:hover {
  text-decoration: underline;
}

vhq-deviceprofile > *,
vhq-devices > * {
  portal-search-filter-radio[radioName='dateType'] {
    display: none;
  }
}
.has-text-word-break {
  word-break: break-word;
}

/**
 Table Receipt Transaction documents
*/

.has-text-truncate-width {
  max-width: 200px;
}

.has-max-height-250 {
  max-height: 250px !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-name {
  max-width: 13rem;
}

.table.line-items-table {
  border-radius: 0;
  &,
  tbody tr,
  thead tr,
  thead tr th,
  tbody tr td {
    border-radius: 0;
    background: inherit;
  }
  tr:hover,
  tr:hover td,
  td:hover {
    cursor: auto;
    background: inherit;
  }
}

.wrap-content {
  overflow-wrap: break-word;
}

// Todo: check below button styles and remove them if unnecessary

// button filters new design
.button:not([disabled]):not(.is-static):not(.close-detail):not(.is-danger) {
  &.is-multiple-filters {
    .filters-in-line {
      padding-right: 0;
      padding-left: 0;
      text-align: left;
      .filter-text {
        display: inline-block;
        margin-right: 0.3rem;
      }
    }
    .filters-in-line {
      .counter-label span {
        border-radius: 26px;
        padding: 4px 13px;
        width: 1em;
        height: 1em;
      }
    }
  }
}

.payment-link-form {
  .accordion {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    .ng-select .ng-dropdown-panel {
      position: relative !important;
      display: inherit !important;
    }

    .accordion-header:focus-visible:not(.is-invalid) {
      border: 0.1em solid $vf-primary-100 !important;
      outline: 0.1em solid $vf-primary-100 !important;
    }
    .accordion-header.is-invalid:focus-visible {
      border: 0.1em solid $vf-danger-100 !important;
      outline: 0.1em solid $vf-danger-100 !important;
    }
    .accordion-header label.checkbox {
      padding-left: 19px;
    }
    .accordion-header label.checkbox:focus-within {
      border: 0.1em solid !important;
      outline: 0.1em solid !important;
    }
    .accordion-header label.checkbox span:empty {
      padding: 0;
    }
  }

  .image-container {
    display: flex;
    justify-content: end;
  }

  .tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-right: 10px;
  }

  .tooltip-custom {
    visibility: hidden;
    width: max-content;
    position: absolute;
    padding: 10px;
    top: -50px;
    padding-bottom: 0px;
    padding-right: 0px;
    margin-left: 0px;
    transform: translate(-50%);
  }

  .tooltip-container:hover .tooltip-custom {
    visibility: visible;
  }
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.is-flex-wrap-nowrap {
  flex-wrap: nowrap;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.max-width-full {
  max-width: 100%;
}

.table {
  .portal-basic-table-header {
    .is-sortable {
      min-width: calc(100% + 23px);
    }
  }
}

.tabs ul {
  margin: 0.1rem auto 0.2rem 0.1rem !important;
}

.has-padding-right-5px {
  padding-right: 5px;
}

.width-50 {
  width: 50% !important;
}

.white-space-pre {
  white-space: pre !important;
}

.has-background-highlighted {
  background-color: #ccdbfc;
}

@media screen and (min-width: 600px) {
  portal-toast {
    right: 2rem;
  }
}

.is-overflow {
  overflow: visible !important;
}

portal-processor-settlement-detail,
portal-settlement-detail {
  tr,
  td:hover {
    cursor: default;
  }
}
@media screen and (min-width: 768px) {
  .modal-content {
    min-width: max-content;
  }
}
@media screen and (max-width: 480px) {
  .modal .title {
    padding-right: 0 !important;
  }
}

@media (max-width: 767px) {
  .xs-full-width {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .home-product-card {
    min-height: auto !important;
  }
}
.select-control {
  .ng-select-single .ng-select-container .ng-value-container {
    padding-right: 10px;
  }
}

.has-break-all {
  word-break: break-all;
}
portal-merchant-choice-routing {
  .toggle-disabled input[type=checkbox]:disabled+span::before {
    background-color: $vf-primary-100 !important;
  }
}
