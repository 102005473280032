portal-list-blocking-rules {
  .new-rules-container {
    height: calc(100vh - 186px);
  }

  table {
    width: 100%;
    table-layout: auto;
  }
}

portal-update-blocking-rules {
  .info {
    margin-top: 6px;
  }
}

portal-form-blocking-rules {
  div.box {
    padding: 24px;
  }

  .formName {
    width: 217px;
  }

  .label {
    font-weight: 600;
  }

  input {
    min-width: 170px;
  }

  .select {
    width: 100%;

    select {
      width: 100%;
    }
  }

  .columns.is-gapless {
    &:not(:last-child) {
      margin: 0px;
    }

    .column {
      margin-right: 6px;
      margin-bottom: 8px;
    }
  }

  .help.is-danger {
    margin-top: 0px;
    margin-bottom: 0.5rem;
  }
}
