@import './../../../../../../../node_modules/@circlon/angular-tree-component/css/angular-tree-component.css';
@import './../../../../../../../node_modules/swiper/css/swiper.css';
.white-labeled .title {
  color: var(--navbar-item-color) !important;
}
.white-labeled vui-top-common-navbar {
  display: block;
  background-color: var(--navbar-background-color) !important;
}
.white-labeled authentication-root .auth-navbar-background {
  background-color: var(--navbar-background-color) !important;
}
.white-labeled .hero.is-rebrand {
  background: 0 !important;
  height: 153px;
}
.white-labeled .hero.is-rebrand .hero-body {
  padding-top: 4.5rem;
}
.white-labeled .hero.is-rebrand .hero-body .hero-icon {
  display: none;
}
.white-labeled .hero.is-rebrand .hero-body .container .title {
  color: var(--navbar-item-color) !important;
  margin-left: 0 !important;
}
.white-labeled .developer-portal-section {
  display: none;
}
.white-labeled .payment-method-button .has-border-primary {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .payment-method-button .has-text-primary {
  color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .card-number-tag.has-background-primary {
  background-color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .has-background-info-light {
  background-color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .accordions .accordion .accordion-header {
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled .accordions .accordion.is-active .accordion-header {
  background-color: var(--brand-hover-light-background) !important;
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .accordions .accordion.is-active .accordion-header,
.white-labeled .has-border-primary {
  border: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .payment-link-form .accordion .accordion-header:focus-visible:not(.is-invalid) {
  border: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
  outline: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .payment-link-form .accordion .accordion-header.is-invalid:focus-visible {
  border: 0.1em solid #d3135d !important;
  outline: 0.1em solid #d3135d !important;
}
.white-labeled .payment-link-form .accordion .accordion-header label.checkbox:focus-within {
  border: 1px solid !important;
  outline: 1px solid !important;
}
.white-labeled .payment-link-form .accordion .accordion-header label.checkbox span:empty {
  padding: 0;
}
.white-labeled .navbar,
.white-labeled .navbar.is-rebrand {
  background-color: var(--navbar-background-color) !important;
}
.white-labeled .navbar .navbar-dropdown,
.white-labeled .navbar.is-rebrand .navbar-dropdown {
  background-color: var(--navbar-dropdown-background-color);
}
.white-labeled .navbar .navbar-menu,
.white-labeled .navbar.is-rebrand .navbar-menu {
  background-color: var(--navbar-background-color);
}
@media (max-width: 1023px) {
  .white-labeled .navbar .navbar-menu,
.white-labeled .navbar.is-rebrand .navbar-menu {
    background-color: var(--navbar-dropdown-background-color);
  }
}
.white-labeled .navbar a.navbar-item:focus, .white-labeled .navbar a.navbar-item:hover,
.white-labeled .navbar.is-rebrand a.navbar-item:focus,
.white-labeled .navbar.is-rebrand a.navbar-item:hover {
  background-color: var(--navbar-dropdown-item-focus-hover-background-color);
}
.white-labeled .navbar a.navbar-item.is-active,
.white-labeled .navbar.is-rebrand a.navbar-item.is-active {
  color: var(--navbar-dropdown-item-active-color) !important;
  background-color: var(--navbar-dropdown-item-active-background-color) !important;
}
.white-labeled .navbar .navbar-menu-item .navbar-link:focus, .white-labeled .navbar .navbar-menu-item .navbar-link:hover, .white-labeled .navbar .navbar-menu-item .navbar-link.is-active,
.white-labeled .navbar.is-rebrand .navbar-menu-item .navbar-link:focus,
.white-labeled .navbar.is-rebrand .navbar-menu-item .navbar-link:hover,
.white-labeled .navbar.is-rebrand .navbar-menu-item .navbar-link.is-active {
  background-color: var(--navbar-item-link-focus-hover-active-background-color);
  color: var(--navbar-item-link-focus-hover-active-color);
}
.white-labeled .navbar .navbar-start > .navbar-item,
.white-labeled .navbar .navbar-start .navbar-link,
.white-labeled .navbar .navbar-end > .navbar-item,
.white-labeled .navbar .navbar-end .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-start > .navbar-item,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-end > .navbar-item,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link {
  color: var(--navbar-item-color) !important;
}
.white-labeled .navbar .navbar-start > a.navbar-item:focus, .white-labeled .navbar .navbar-start > a.navbar-item:hover, .white-labeled .navbar .navbar-start > a.navbar-item.is-active,
.white-labeled .navbar .navbar-start .navbar-link:focus,
.white-labeled .navbar .navbar-start .navbar-link:hover,
.white-labeled .navbar .navbar-start .navbar-link.is-active,
.white-labeled .navbar .navbar-end > a.navbar-item:focus,
.white-labeled .navbar .navbar-end > a.navbar-item:hover,
.white-labeled .navbar .navbar-end > a.navbar-item.is-active,
.white-labeled .navbar .navbar-end .navbar-link:focus,
.white-labeled .navbar .navbar-end .navbar-link:hover,
.white-labeled .navbar .navbar-end .navbar-link.is-active,
.white-labeled .navbar.is-rebrand .navbar-start > a.navbar-item:focus,
.white-labeled .navbar.is-rebrand .navbar-start > a.navbar-item:hover,
.white-labeled .navbar.is-rebrand .navbar-start > a.navbar-item.is-active,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link:focus,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link:hover,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link.is-active,
.white-labeled .navbar.is-rebrand .navbar-end > a.navbar-item:focus,
.white-labeled .navbar.is-rebrand .navbar-end > a.navbar-item:hover,
.white-labeled .navbar.is-rebrand .navbar-end > a.navbar-item.is-active,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link:focus,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link:hover,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link.is-active {
  background-color: var(--navbar-background-color) !important;
  color: var(--navbar-item-color) !important;
}
.white-labeled .navbar .navbar-start .navbar-link::after,
.white-labeled .navbar .navbar-end .navbar-link::after,
.white-labeled .navbar.is-rebrand .navbar-start .navbar-link::after,
.white-labeled .navbar.is-rebrand .navbar-end .navbar-link::after {
  border-color: var(--navbar-item-link-border-color);
}
.white-labeled .navbar .navbar-item.has-dropdown:focus .navbar-link,
.white-labeled .navbar .navbar-item.has-dropdown:hover .navbar-link,
.white-labeled .navbar .navbar-item.has-dropdown.is-active .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown:focus .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown:hover .navbar-link,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: var(--navbar-background-color);
  color: var(--navbar-item-link-color);
}
.white-labeled .navbar .navbar-item.has-dropdown:focus .navbar-link span,
.white-labeled .navbar .navbar-item.has-dropdown:hover .navbar-link span,
.white-labeled .navbar .navbar-item.has-dropdown.is-active .navbar-link span,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown:focus .navbar-link span,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown:hover .navbar-link span,
.white-labeled .navbar.is-rebrand .navbar-item.has-dropdown.is-active .navbar-link span {
  border-bottom: 1px solid var(--navbar-item-link-color) !important;
}
.white-labeled .navbar-item,
.white-labeled .navbar-link {
  color: var(--navbar-item-color);
}
.white-labeled a.navbar-item,
.white-labeled .navbar-link {
  color: var(--navbar-item-color) !important;
  cursor: pointer;
}
.white-labeled a.navbar-item:focus, .white-labeled a.navbar-item:focus-within, .white-labeled a.navbar-item:hover, .white-labeled a.navbar-item.is-active,
.white-labeled .navbar-link:focus,
.white-labeled .navbar-link:focus-within,
.white-labeled .navbar-link:hover,
.white-labeled .navbar-link.is-active {
  background-color: var(--navbar-item-hover-background-color);
  color: var(--navbar-item-hover-color);
}
.white-labeled .navbar-item:hover .navbar-item-inner, .white-labeled .navbar-item.is-active .navbar-item-inner {
  background: var(--navbar-item-hover-background-color);
  color: var(--navbar-item-inner-hover-color);
}
.white-labeled .navbar-item:hover .navbar-item-inner .arrow, .white-labeled .navbar-item.is-active .navbar-item-inner .arrow {
  color: var(--navbar-item-inner-hover-color);
}
.white-labeled .navbar-item-inner {
  color: var(--navbar-item-color);
}
.white-labeled .navbar-item.has-circle .is-active .circle,
.white-labeled .navbar-item.has-circle .is-active .arrow {
  color: var(--navbar-item-color);
}
.white-labeled .navbar-item.has-circle .navbar-link:hover .circle,
.white-labeled .navbar-item.has-circle .navbar-link:hover .arrow, .white-labeled .navbar-item.has-circle .navbar-link.is-active .circle,
.white-labeled .navbar-item.has-circle .navbar-link.is-active .arrow {
  color: var(--navbar-item-color);
}
.white-labeled .navbar-link:not(.is-arrowless) .arrow {
  color: var(--navbar-item-color);
}
.white-labeled .navbar-brand a.navbar-item:focus, .white-labeled .navbar-brand a.navbar-item:hover {
  background-color: transparent;
}
.white-labeled .navbar-burger span {
  color: var(--navbar-item-color) !important;
}
.white-labeled .splash__bar {
  background-color: var(--navbar-background-color);
}
.white-labeled footer.footer {
  background-color: var(--navbar-background-color);
}
.white-labeled footer.footer .footer-bar {
  color: var(--navbar-item-color);
}
.white-labeled footer.footer .footer-items {
  color: var(--navbar-item-color);
}
.white-labeled .term {
  color: var(--primary-button-active-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail):not(.is-chip) {
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail):focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail):hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-hovered {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail):active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-outlined-active-border-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-text {
  color: var(--primary-button-outlined-text-color);
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-text:focus-visible {
  border: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary {
  color: var(--primary-button-text-color) !important;
  background-color: var(--primary-button-background-color);
  text-decoration-color: var(--primary-button-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-focused {
  color: var(--primary-button-hover-focus-text-color) !important;
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-focused {
  border-color: var(--brand-outlined-text) !important;
  outline: 1px solid var(--brand-outlined-text) !important;
  outline-offset: 0 !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined {
  background-color: var(--primary-button-outlined-background-color);
  border-color: var(--primary-button-outlined-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined.is-focused {
  color: var(--primary-button-outlined-hover-focus-text-color) !important;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-primary.is-text {
  color: var(--primary-button-outlined-text-color);
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-focused {
  color: var(--primary-button-hover-focus-text-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-focused {
  border-color: var(--primary-button-focus-border-color) !important;
  outline: var(--primary-button-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined {
  background-color: inherit;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-active-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined.is-focused {
  color: var(--primary-button-text-color);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-danger.is-text {
  color: var(--primary-button-active-border-color) !important;
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary {
  color: var(--primary-button-text-color) !important;
  background-color: var(--primary-button-background-color);
  text-decoration-color: var(--primary-button-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-outlined-active-border-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined {
  background-color: var(--primary-button-outlined-background-color);
  border-color: var(--primary-button-outlined-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-focused {
  color: var(--primary-button-outlined-hover-focus-text-color) !important;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-primary.is-text {
  color: var(--primary-button-outlined-text-color) !important;
  box-shadow: none;
  border-color: transparent !important;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  color: var(--primary-button-hover-focus-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:focus-visible, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-focused {
  color: var(--primary-button-hover-focus-text-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined {
  background-color: inherit;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-active-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-focused {
  color: var(--primary-button-text-color);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).has-text-danger.is-text {
  color: var(--primary-button-active-border-color) !important;
  box-shadow: none;
  border-color: transparent !important;
  background-color: transparent;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-hovered, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:hover, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-focused, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-applied {
  color: var(--primary-button-active-border-color);
  border-color: var(--primary-button-active-border-color) !important;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:focus, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-focused, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-applied {
  background-color: var(--primary-button-chip-active-background-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:focus.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-focused.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-active.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip:active.is-active, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-chip.is-applied.is-active {
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).additional-filter-button, .white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).button-clear-filter {
  color: var(--primary-button-outlined-text-color);
  text-decoration-color: var(--primary-button-text-color);
  box-shadow: none;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).icon {
  color: var(--primary-button-outlined-text-color);
  border-color: transparent;
  box-shadow: none;
}
.white-labeled .button:not([disabled]):not(.is-static):not(.close-detail).is-small {
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail):not(.is-chip) {
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail):focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail):active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-outlined-active-border-color);
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-text {
  color: var(--primary-button-outlined-text-color);
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-outlined {
  background-color: var(--primary-button-outlined-background-color);
  border-color: var(--primary-button-outlined-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-outlined:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-primary.is-text {
  color: var(--primary-button-outlined-text-color);
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-focused {
  color: var(--primary-button-hover-focus-text-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:focus-visible, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-focused {
  border-color: var(--primary-button-focus-border-color) !important;
  outline: var(--primary-button-focus-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined {
  background-color: inherit;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-active-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined.is-focused {
  color: var(--primary-button-text-color);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-danger.is-text {
  color: var(--primary-button-active-border-color) !important;
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary {
  color: var(--primary-button-text-color) !important;
  background-color: var(--primary-button-background-color);
  text-decoration-color: var(--primary-button-text-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:focus-visible, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:focus-visible, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-outlined-active-border-color) !important;
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined {
  background-color: var(--primary-button-outlined-background-color);
  border-color: var(--primary-button-outlined-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-focused {
  color: var(--primary-button-outlined-hover-focus-text-color) !important;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-primary.is-text {
  color: var(--primary-button-outlined-text-color) !important;
  box-shadow: none;
  border-color: transparent !important;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger {
  color: var(--primary-button-text-color);
  background-color: var(--primary-button-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-focused {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:focus-visible, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-focused {
  color: var(--primary-button-hover-focus-text-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-active {
  background-color: var(--primary-button-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-loading::before {
  border-color: var(--primary-button-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined {
  background-color: inherit;
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-border-color);
  color: var(--primary-button-active-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-hovered, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined:hover, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined:focus, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-focused {
  color: var(--primary-button-text-color);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  outline: var(--primary-button-outlined-hover-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
  background-color: var(--primary-button-hover-focus-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-active {
  background-color: var(--primary-button-outlined-active-background-color);
  border-color: var(--primary-button-active-border-color);
  outline: var(--primary-button-active-border-color);
  color: var(--primary-button-outlined-active-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-outlined.is-loading::before {
  border-color: var(--primary-button-outlined-text-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).has-text-danger.is-text {
  color: var(--primary-button-active-border-color) !important;
  box-shadow: none;
  border-color: transparent !important;
  background-color: transparent;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-applied {
  color: var(--primary-button-active-border-color);
  border-color: var(--primary-button-active-border-color) !important;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip:active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-applied {
  background-color: var(--primary-button-chip-active-background-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-active.is-active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip:active.is-active, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-chip.is-applied.is-active {
  outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).additional-filter-button, .white-labeled .button[disabled]:not(.is-static):not(.close-detail).button-clear-filter {
  color: var(--primary-button-outlined-text-color);
  text-decoration-color: var(--primary-button-text-color);
  box-shadow: none;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).icon {
  color: var(--primary-button-outlined-text-color);
  border-color: transparent;
  box-shadow: none;
}
.white-labeled .button[disabled]:not(.is-static):not(.close-detail).is-small {
  color: var(--primary-button-outlined-text-color);
}
.white-labeled .button.is-transparent {
  color: var(--primary-button-text-color);
}
.white-labeled .button.is-transparent.close-detail {
  color: var(--primary-button-active-border-color) !important;
}
.white-labeled .button.is-transparent.close-detail:hover,
.white-labeled .button:hover {
  outline: 1px solid var(--primary-button-active-border-color) !important;
  color: var(--primary-button-outlined-text-color);
}
.white-labeled vui-kpi .button:hover {
  box-shadow: none !important;
}
.white-labeled .mat-chip-list mat-chip.mat-chip {
  color: var(--primary-button-active-border-color);
  border-color: var(--primary-button-active-border-color);
  background-color: var(--primary-button-chip-active-background-color);
}
.white-labeled .dropdown-content {
  border-color: var(--dropdown-content-border-color);
  background-color: var(--dropdown-content-background-color);
  box-shadow: 0px 4px 14px var(--dropdown-content-box-shadow-color);
}
.white-labeled .dropdown-menu.add-filter-menu a.dropdown-item:focus {
  background-color: var(--dropdown-item-filter-focus-background-color);
}
.white-labeled a.dropdown-item {
  color: var(--dropdown-item-color);
}
.white-labeled a.dropdown-item:hover, .white-labeled a.dropdown-item:focus, .white-labeled a.dropdown-item:active {
  color: var(--dropdown-item-color);
  background-color: var(--dropdown-item-hover-background-color);
}
.white-labeled a.dropdown-item.is-active {
  color: var(--dropdown-item-active-color) !important;
  background-color: var(--dropdown-item-active-background-color) !important;
}
.white-labeled mat-tree .tree-item:hover,
.white-labeled mat-tree mat-tree-node:hover {
  background-color: var(--dropdown-item-hover-background-color);
}
.white-labeled .input:active,
.white-labeled .input:focus,
.white-labeled .is-active.input,
.white-labeled .is-active.textarea,
.white-labeled .is-focused.input,
.white-labeled .is-focused.textarea,
.white-labeled .select select.is-active,
.white-labeled .select select.is-focused,
.white-labeled .select select:active,
.white-labeled .select select:focus,
.white-labeled .textarea:active,
.white-labeled .textarea:focus {
  border-color: var(--control-active-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color);
}
.white-labeled input[type=radio]:checked + span::before {
  border-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=radio]:checked + span::after {
  background-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=radio]:focus + span::before {
  border-color: var(--control-active-focus-border-color) !important;
  box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color) !important;
}
.white-labeled input[type=radio]:focus + span::after {
  background-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=checkbox]:checked + span::before,
.white-labeled input[type=checkbox]:checked + label::before,
.white-labeled input[type=checkbox]:indeterminate + span::before,
.white-labeled input[type=checkbox]:indeterminate + label::before {
  border-color: var(--control-active-focus-border-color) !important;
  background-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=checkbox]:focus + span::before,
.white-labeled input[type=checkbox]:focus + label::before {
  outline: 1px solid var(--control-active-focus-border-color) !important;
  border-color: var(--control-active-focus-border-color) !important;
}
.white-labeled input[type=text].copy-input {
  color: var(--primary-button-outlined-text-color) !important;
}
.white-labeled a,
.white-labeled a:hover,
.white-labeled a:focus,
.white-labeled a:visited {
  color: var(--link-color) !important;
}
.white-labeled a.has-text-dark {
  color: var(--navbar-item-color) !important;
}
.white-labeled i.has-text-link {
  border-color: var(--link-color);
}
.white-labeled .has-text-link,
.white-labeled .has-text-link:hover,
.white-labeled .has-text-link:active {
  color: var(--link-color) !important;
}
.white-labeled .steps:not(.is-hollow) .steps-marker:not(.is-hollow),
.white-labeled .steps:not(.is-hollow) .steps-segment::after,
.white-labeled .steps:not(.is-hollow) .steps-segment.is-active .steps-marker:not(.is-hollow) {
  background-color: var(--step-background-color) !important;
}
.white-labeled .steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment::after,
.white-labeled .steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow),
.white-labeled .steps:not(.is-hollow) .steps-segment.is-active::after {
  background-color: var(--step-default-background-color) !important;
}
.white-labeled .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, .white-labeled .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: var(--ng-select-background-color) !important;
}
.white-labeled .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--ng-select-background-color);
}
.white-labeled .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right-color: var(--ng-select-tag-border-color);
}
.white-labeled .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:hover {
  background-color: var(--ng-select-background-color);
}
.white-labeled .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: var(--control-active-focus-border-color);
  box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color);
}
.white-labeled ngb-datepicker .ngb-dp-arrow-btn {
  color: var(--ng-datepicker-weekdays-arrows);
}
.white-labeled ngb-datepicker .ngb-dp-weekdays .ngb-dp-weekday {
  color: var(--brand-background);
}
.white-labeled ngb-datepicker .disabled .custom-day {
  color: var(--brand-background);
  opacity: 0.3;
}
.white-labeled ngb-datepicker .custom-day:hover, .white-labeled ngb-datepicker .custom-day.is-hovered, .white-labeled ngb-datepicker .custom-day.first.range, .white-labeled ngb-datepicker .custom-day.last.range {
  background-color: var(--ng-datepicker-custom-day-background-color);
}
.white-labeled ngb-datepicker .custom-day.faded {
  background-color: var(--ng-datepicker-range-background-color);
}
.white-labeled .tabs li.is-active a,
.white-labeled .sidepanel.sidepanel--theme-winter .tabs li.is-active a {
  color: var(--tab-color);
  border-bottom-color: var(--tab-border-color);
}
.white-labeled .table.is-hoverable tbody > tr:not(.is-selected):focus-within,
.white-labeled .table.is-hoverable tbody > tr:not(.is-selected):hover {
  background-color: var(--table-background-color);
}
.white-labeled .node-content-wrapper:hover {
  box-shadow: none;
  background-color: var(--tree-wrapper-hover-background-color);
}
.white-labeled .node-content-wrapper.node-content-wrapper-focused:hover, .white-labeled .node-content-wrapper.node-content-wrapper-focused {
  box-shadow: none;
  background-color: var(--tree-wrapper-active-background-color);
}
.white-labeled tree-root .node-content-wrapper-active {
  background: none;
  box-shadow: none;
}
.white-labeled tree-root .node-content-wrapper-active:hover {
  background: none;
}
.white-labeled tree-node-wrapper input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid var(--brand-outlined-border);
  color: #ffffff;
}
.white-labeled tree-node-wrapper input[type=checkbox]:disabled {
  border-color: #cccccc;
  opacity: 1;
  cursor: default;
}
.white-labeled tree-node-wrapper input[type=checkbox]:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  border-color: var(--control-active-focus-border-color);
  outline: 1px solid var(--control-active-focus-border-color);
}
.white-labeled tree-node-wrapper input[type=checkbox]:indeterminate:after {
  background-color: var(--control-active-focus-border-color);
}
.white-labeled tree-node-wrapper input[type=checkbox]:checked:before {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-color: var(--control-active-focus-border-color) !important;
  background-color: var(--control-active-focus-border-color) !important;
}
.white-labeled tree-node-wrapper input[type=checkbox]:checked:after {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: var(--control-active-focus-border-color);
  border-color: var(--brand-outlined-border);
}
.white-labeled .load-more-container .spinner-container .spinner::before {
  border: 0.25rem solid var(--control-active-focus-border-color);
  border-top-color: var(--control-active-focus-border-color);
  border-top-style: solid;
  border-top-width: 0.25rem;
  border-right-color: transparent;
  border-right-style: solid;
  border-right-width: 0.25rem;
  border-bottom-color: var(--control-active-focus-border-color);
  border-bottom-style: solid;
  border-bottom-width: 0.25rem;
  border-left-color: var(--control-active-focus-border-color);
  border-left-style: solid;
  border-left-width: 0.25rem;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}
.white-labeled footer {
  display: none;
}
.white-labeled .notification.is-info {
  background-color: var(--brand-hover-light-background);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
  color: #262626 !important;
}
.white-labeled .home-product-card .home-product-card-right .no-kpi-background {
  border: 1px solid var(--home-card-kpi-border-color);
  border-radius: 32px;
}
.white-labeled .has-background-highlighted {
  background-color: var(--brand-hover-light-background);
  border-color: var(--primary-button-outlined-hover-focus-border-color);
}

.button.is-text:active,
.button.is-text:focus,
.button.is-text:hover,
.button.is-text:focus-visible {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
}

.button.is-primary.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-outlined {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  color: var(--primary-button-outlined-hover-focus-border-color) !important;
}

@media screen and (max-width: 1023px) {
  .white-labeled .hero.is-rebrand {
    background: 0 !important;
    height: auto;
    margin-bottom: inherit;
    top: inherit;
    max-height: inherit;
    padding-top: inherit;
  }
  .white-labeled .hero.is-rebrand .hero-body {
    padding: 1.5rem 0 0 1.5rem;
  }
  .white-labeled .hero.is-rebrand .hero-body .title {
    font-size: 2rem;
  }
}
.white-labeled {
  font-family: var(--font, "Noto Sans"), sans-serif;
}
.white-labeled body,
.white-labeled button,
.white-labeled input,
.white-labeled optgroup,
.white-labeled select,
.white-labeled textarea,
.white-labeled .tooltip::before,
.white-labeled .navbar {
  font-family: var(--font, "Noto Sans"), sans-serif;
}