//@charset "utf-8";

//solids --white mix: darken
$vf-white-100: #ffffff;
$vf-black-100: #000000;

$vf-white-95: mix($vf-black-100, $vf-white-100, 5%);
$vf-white-90: mix($vf-black-100, $vf-white-100, 10%);
$vf-white-85: mix($vf-black-100, $vf-white-100, 15%);
$vf-white-80: mix($vf-black-100, $vf-white-100, 20%);
$vf-white-70: mix($vf-black-100, $vf-white-100, 30%);
$vf-white-60: mix($vf-black-100, $vf-white-100, 40%);
$vf-white-50: mix($vf-black-100, $vf-white-100, 50%);
$vf-white-40: mix($vf-black-100, $vf-white-100, 60%);
$vf-white-30: mix($vf-black-100, $vf-white-100, 70%);
$vf-white-25: mix($vf-black-100, $vf-white-100, 75%);
$vf-white-20: mix($vf-black-100, $vf-white-100, 80%);
$vf-white-10: mix($vf-black-100, $vf-white-100, 90%);
$vf-white-5: mix($vf-black-100, $vf-white-100, 95%);


$vf-black-95: mix($vf-white-100, $vf-black-100, 5%);
$vf-black-90: mix($vf-white-100, $vf-black-100, 10%);
$vf-black-85: mix($vf-white-100, $vf-black-100, 15%);
$vf-black-80: mix($vf-white-100, $vf-black-100, 20%);
$vf-black-70: mix($vf-white-100, $vf-black-100, 30%);
$vf-black-60: mix($vf-white-100, $vf-black-100, 40%);
$vf-black-50: mix($vf-white-100, $vf-black-100, 50%);
$vf-black-40: mix($vf-white-100, $vf-black-100, 60%);
$vf-black-30: mix($vf-white-100, $vf-black-100, 70%);
$vf-black-25: mix($vf-white-100, $vf-black-100, 75%);
$vf-black-20: mix($vf-white-100, $vf-black-100, 80%);
$vf-black-15: mix($vf-white-100, $vf-black-100, 85%);
$vf-black-10: mix($vf-white-100, $vf-black-100, 90%);
$vf-black-5: mix($vf-white-100, $vf-black-100, 95%);


$vf-primary-100: #004aef;
$vf-primary-80: mix($vf-white-100, $vf-primary-100, 20%);
$vf-primary-75: mix($vf-white-100, $vf-primary-100, 25%);
$vf-primary-50: mix($vf-white-100, $vf-primary-100, 50%);
$vf-primary-30: mix($vf-white-100, $vf-primary-100, 70%);
$vf-primary-20: mix($vf-white-100, $vf-primary-100, 80%);
$vf-primary-15: mix($vf-white-100, $vf-primary-100, 85%);

$vf-link-100: #004aef;
$vf-link-50: mix($vf-white-100, $vf-link-100, 50%);
$vf-link-20: mix($vf-white-100, $vf-link-100, 80%);

$vf-accent-100: #6effd2;
$vf-accent-50: mix($vf-white-100, $vf-accent-100, 50%);
$vf-accent-20: mix($vf-white-100, $vf-accent-100, 80%);

$vf-info-100: #004aef;
$vf-info-80: mix($vf-white-100, $vf-primary-100, 20%);
$vf-info-50: mix($vf-white-100, $vf-primary-100, 50%);
$vf-info-30: mix($vf-white-100, $vf-primary-100, 70%);
$vf-info-20: mix($vf-white-100, $vf-primary-100, 80%);
$vf-info-15: mix($vf-white-100, $vf-primary-100, 85%);

$vf-success-100: #05c98c;
$vf-success-50: mix($vf-white-100, $vf-success-100, 50%);
$vf-success-20: mix($vf-white-100, $vf-success-100, 80%);

$vf-warning-100: #fce544;
$vf-warning-50: mix($vf-white-100, $vf-warning-100, 50%);
$vf-warning-20: mix($vf-white-100, $vf-warning-100, 80%);

$vf-danger-100: #d3135d;
$vf-danger-50: mix($vf-white-100, $vf-danger-100, 50%);
$vf-danger-20: mix($vf-white-100, $vf-danger-100, 80%);

$vf-orange-100: #fc704f;
$vf-orange-50: mix($vf-white-100, $vf-orange-100, 50%);
$vf-orange-20: mix($vf-white-100, $vf-orange-100, 80%);

$vf-purple-100: #7225d6;
$vf-purple-50: mix($vf-white-100, $vf-purple-100, 50%);
$vf-purple-20: mix($vf-white-100, $vf-purple-100, 80%);

$vf-kpi-pass: $vf-success-100;
$vf-kpi-info: $vf-info-100;
$vf-kpi-error: $vf-danger-100;
$vf-kpi-warning: $vf-warning-100;

// chart colors
$vf-chart-color-1: #f2f2f2;
$vf-chart-color-2: #a6a6a6;
$vf-chart-color-3: #595959;
$vf-chart-color-4: #0d0d0d;

$vf-chart-color-a: $vf-success-100;
$vf-chart-color-a1: mix($vf-chart-color-1, $vf-chart-color-a);
$vf-chart-color-a2: mix($vf-chart-color-2, $vf-chart-color-a);
$vf-chart-color-a3: mix($vf-chart-color-3, $vf-chart-color-a);
$vf-chart-color-a4: mix($vf-chart-color-4, $vf-chart-color-a);

$vf-chart-color-b: $vf-purple-100;
$vf-chart-color-b1: mix($vf-chart-color-1, $vf-chart-color-b);
$vf-chart-color-b2: mix($vf-chart-color-2, $vf-chart-color-b);
$vf-chart-color-b3: mix($vf-chart-color-3, $vf-chart-color-b);
$vf-chart-color-b4: mix($vf-chart-color-4, $vf-chart-color-b);

$vf-chart-color-c: $vf-primary-100;
$vf-chart-color-c1: mix($vf-chart-color-1, $vf-chart-color-c);
$vf-chart-color-c2: mix($vf-chart-color-2, $vf-chart-color-c);
$vf-chart-color-c3: mix($vf-chart-color-3, $vf-chart-color-c);
$vf-chart-color-c4: mix($vf-chart-color-4, $vf-chart-color-c);

$vf-chart-color-d: $vf-danger-100;
$vf-chart-color-d1: mix($vf-chart-color-1, $vf-chart-color-d);
$vf-chart-color-d2: mix($vf-chart-color-2, $vf-chart-color-d);
$vf-chart-color-d3: mix($vf-chart-color-3, $vf-chart-color-d);
$vf-chart-color-d4: mix($vf-chart-color-4, $vf-chart-color-d);

$vf-chart-color-e: $vf-orange-100;
$vf-chart-color-e1: mix($vf-chart-color-1, $vf-chart-color-e);
$vf-chart-color-e2: mix($vf-chart-color-2, $vf-chart-color-e);
$vf-chart-color-e3: mix($vf-chart-color-3, $vf-chart-color-e);
$vf-chart-color-e4: mix($vf-chart-color-4, $vf-chart-color-e);

$vf-chart-color-f: $vf-warning-100;
$vf-chart-color-f1: mix($vf-chart-color-1, $vf-chart-color-f);
$vf-chart-color-f2: mix($vf-chart-color-2, $vf-chart-color-f);
$vf-chart-color-f3: mix($vf-chart-color-3, $vf-chart-color-f);
$vf-chart-color-f4: mix($vf-chart-color-4, $vf-chart-color-f);

// 0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80
$vf-chart-color-g: $vf-black-100;
$vf-chart-color-g0: mix($vf-white-100, $vf-chart-color-g, 80%);
$vf-chart-color-g1: mix($vf-white-100, $vf-chart-color-g, 72%);
$vf-chart-color-g2: mix($vf-white-100, $vf-chart-color-g, 64%);
$vf-chart-color-g3: mix($vf-white-100, $vf-chart-color-g, 56%);
$vf-chart-color-g4: mix($vf-white-100, $vf-chart-color-g, 48%);
$vf-chart-color-g5: mix($vf-white-100, $vf-chart-color-g, 40%);
$vf-chart-color-g6: mix($vf-white-100, $vf-chart-color-g, 32%);
$vf-chart-color-g7: mix($vf-white-100, $vf-chart-color-g, 24%);
$vf-chart-color-g8: mix($vf-white-100, $vf-chart-color-g, 16%);
$vf-chart-color-g9: mix($vf-white-100, $vf-chart-color-g, 8%);

//transparent
$vf-null: rgba($vf-white-100, 0%);
