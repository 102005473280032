.white-labeled {
  font-family: var(--font, 'Noto Sans'), sans-serif;

  body,
  button,
  input,
  optgroup,
  select,
  textarea,
  .tooltip::before,
  .navbar {
    font-family: var(--font, 'Noto Sans'), sans-serif;
  }
}
