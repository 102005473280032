.white-labeled {
  // portal fix till UDS fixes logo issue SGS-13306

  .title {
    color: var(--navbar-item-color) !important;
  }

  vui-top-common-navbar {
    display: block;
    background-color: var(--navbar-background-color) !important;
  }

  authentication-root {
    .auth-navbar-background {
      background-color: var(--navbar-background-color) !important;
    }
  }

  .hero.is-rebrand {
    background: 0 !important;
    height: 153px;

    .hero-body {
      padding-top: 4.5rem;

      .hero-icon {
        display: none;
      }

      .container {
        .title {
          color: var(--navbar-item-color) !important;
          margin-left: 0 !important;
        }
      }
    }
  }

  // main
  .developer-portal-section {
    display: none;
  }

  .payment-method-button {
    .has-border-primary {
      border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
    }
    .has-text-primary {
      color: var(--primary-button-outlined-hover-focus-border-color) !important;
    }
  }

  .card-number-tag.has-background-primary {
    background-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  }

  .has-background-info-light {
    background-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  }

  .accordions .accordion .accordion-header {
    color: var(--primary-button-outlined-text-color) !important;
  }

  .accordions .accordion.is-active .accordion-header {
    background-color: var(--brand-hover-light-background) !important;
    border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  }

  .accordions .accordion.is-active .accordion-header,
  .has-border-primary {
    border: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
  }

  .payment-link-form {
    .accordion {
      .accordion-header:focus-visible:not(.is-invalid) {
        border: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
        outline: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
      }
      .accordion-header.is-invalid:focus-visible {
        border: 0.1em solid $vf-danger-100 !important;
        outline: 0.1em solid $vf-danger-100 !important;
      }
      .accordion-header label.checkbox:focus-within {
        border: 1px solid !important;
        outline: 1px solid !important;
      }
      .accordion-header label.checkbox span:empty {
        padding: 0;
      }
    }
  }

  // navbar
  .navbar,
  .navbar.is-rebrand {
    background-color: var(--navbar-background-color) !important;

    .navbar-dropdown {
      background-color: var(--navbar-dropdown-background-color);
    }

    .navbar-menu {
      background-color: var(--navbar-background-color);
    }

    @media (max-width: 1023px) {
      .navbar-menu {
        background-color: var(--navbar-dropdown-background-color);
      }
    }

    a.navbar-item {
      &:focus,
      &:hover {
        background-color: var(--navbar-dropdown-item-focus-hover-background-color);
      }

      &.is-active {
        color: var(--navbar-dropdown-item-active-color) !important;
        background-color: var(--navbar-dropdown-item-active-background-color) !important;
      }
    }

    .navbar-menu-item {
      .navbar-link {
        &:focus,
        &:hover,
        &.is-active {
          background-color: var(--navbar-item-link-focus-hover-active-background-color);
          color: var(--navbar-item-link-focus-hover-active-color);
        }
      }
    }

    .navbar-start,
    .navbar-end {
      & > .navbar-item,
      .navbar-link {
        color: var(--navbar-item-color) !important;
      }

      & > a.navbar-item,
      .navbar-link {
        &:focus,
        &:hover,
        &.is-active {
          background-color: var(--navbar-background-color) !important;
          color: var(--navbar-item-color) !important;
        }
      }

      .navbar-link {
        &::after {
          border-color: var(--navbar-item-link-border-color);
        }
      }
    }

    .navbar-item.has-dropdown:focus .navbar-link,
    .navbar-item.has-dropdown:hover .navbar-link,
    .navbar-item.has-dropdown.is-active .navbar-link {
      background-color: var(--navbar-background-color);
      color: var(--navbar-item-link-color);
      span {
        border-bottom: 1px solid var(--navbar-item-link-color) !important;
      }
    }
  }

  .navbar-item,
  .navbar-link {
    color: var(--navbar-item-color);
  }

  a.navbar-item,
  .navbar-link {
    color: var(--navbar-item-color) !important;
    cursor: pointer;

    &:focus,
    &:focus-within,
    &:hover,
    &.is-active {
      background-color: var(--navbar-item-hover-background-color);
      color: var(--navbar-item-hover-color);
    }
  }

  .navbar-item {
    &:hover,
    &.is-active {
      .navbar-item-inner {
        background: var(--navbar-item-hover-background-color);
        color: var(--navbar-item-inner-hover-color);
        .arrow {
          color: var(--navbar-item-inner-hover-color);
        }
      }
    }
    &-inner {
      color: var(--navbar-item-color);
    }

    &.has-circle {
      .is-active {
        .circle,
        .arrow {
          color: var(--navbar-item-color);
        }
      }
      .navbar-link {
        &:hover,
        &.is-active {
          .circle,
          .arrow {
            color: var(--navbar-item-color);
          }
        }
      }
    }
  }

  .navbar-link:not(.is-arrowless) {
    .arrow {
      color: var(--navbar-item-color);
    }
  }

  .navbar-brand {
    a.navbar-item {
      &:focus,
      &:hover {
        background-color: transparent;
      }
    }
  }

  .navbar-burger span {
    color: var(--navbar-item-color) !important;
  }

  .splash__bar {
    background-color: var(--navbar-background-color);
  }

  // footer
  footer.footer {
    background-color: var(--navbar-background-color);
    .footer-bar {
      color: var(--navbar-item-color);
    }
    .footer-items {
      color: var(--navbar-item-color);
    }
  }

  .term {
    color: var(--primary-button-active-border-color);
  }

  // button
  .button:not([disabled]):not(.is-static):not(.close-detail) {
    &:not(.is-chip) {
      color: var(--primary-button-outlined-text-color);
    }

    &:focus,
    &.is-focused {
      border-color: var(--primary-button-outlined-hover-focus-border-color);
      box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
      outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
    }

    &:hover,
    &.is-hovered {
      border-color: var(--primary-button-outlined-hover-focus-border-color);
      box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
      outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
    }

    &:active,
    &.is-active {
      background-color: var(--primary-button-outlined-active-background-color);
      border-color: var(--primary-button-outlined-active-border-color);
      outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
    }

    &.is-loading::before {
      border-color: var(--primary-button-outlined-text-color);
    }

    &.is-text {
      color: var(--primary-button-outlined-text-color);
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
    }
    &.is-text:focus-visible {
      border: 0.1em solid var(--primary-button-outlined-hover-focus-border-color) !important;
    }

    &.is-primary {
      color: var(--primary-button-text-color) !important;
      background-color: var(--primary-button-background-color);
      text-decoration-color: var(--primary-button-text-color) !important;

      &.is-hovered,
      &:hover,
      &:focus,
      &.is-focused {
        color: var(--primary-button-hover-focus-text-color) !important;
        background-color: var(--primary-button-hover-focus-background-color);
      }

      &:focus,
      &:focus-visible,
      &.is-focused {
        border-color: var(--brand-outlined-text) !important;
        outline: 1px solid var(--brand-outlined-text) !important;
        outline-offset: 0 !important;
      }

      &:active,
      &.is-active {
        background-color: var(--primary-button-active-background-color);
        border-color: var(--primary-button-active-border-color);
        outline: var(--primary-button-active-border-color);
        color: var(--primary-button-active-text-color);
      }

      &.is-loading::before {
        border-color: var(--primary-button-text-color);
      }

      &.is-outlined {
        background-color: var(--primary-button-outlined-background-color);
        border-color: var(--primary-button-outlined-border-color);
        outline: var(--primary-button-outlined-border-color);
        color: var(--primary-button-outlined-text-color) !important;
        &.is-hovered,
        &:hover,
        &:focus,
        &.is-focused {
          color: var(--primary-button-outlined-hover-focus-text-color) !important;
          border-color: var(--primary-button-outlined-hover-focus-border-color);
          outline: var(--primary-button-outlined-hover-focus-border-color);
          box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
        }
        &:active,
        &.is-active {
          background-color: var(--primary-button-outlined-active-background-color);
          border-color: var(--primary-button-active-border-color);
          outline: var(--primary-button-active-border-color);
          color: var(--primary-button-outlined-active-text-color);
        }
        &.is-loading::before {
          border-color: var(--primary-button-outlined-text-color);
        }
      }

      &.is-text {
        color: var(--primary-button-outlined-text-color);
        box-shadow: none;
        border-color: transparent;
        background-color: transparent;
      }
    }

    &.is-danger {
      color: var(--primary-button-text-color);
      background-color: var(--primary-button-background-color);

      &.is-hovered,
      &:hover,
      &:focus,
      &.is-focused {
        color: var(--primary-button-hover-focus-text-color);
        background-color: var(--primary-button-hover-focus-background-color);
      }

      &:focus,
      &:focus-visible,
      &.is-focused {
        border-color: var(--primary-button-focus-border-color) !important;
        outline: var(--primary-button-focus-border-color) !important;
      }

      &:active,
      &.is-active {
        background-color: var(--primary-button-active-background-color);
        border-color: var(--primary-button-active-border-color);
        outline: var(--primary-button-active-border-color);
        color: var(--primary-button-active-text-color);
      }

      &.is-loading::before {
        border-color: var(--primary-button-text-color);
      }

      &.is-outlined {
        background-color: inherit;
        border-color: var(--primary-button-outlined-hover-focus-border-color);
        outline: var(--primary-button-outlined-border-color);
        color: var(--primary-button-active-border-color);
        &.is-hovered,
        &:hover,
        &:focus,
        &.is-focused {
          color: var(--primary-button-text-color);

          border-color: var(--primary-button-outlined-hover-focus-border-color);
          outline: var(--primary-button-outlined-hover-focus-border-color);
          box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
          background-color: var(--primary-button-hover-focus-background-color);
        }
        &:active,
        &.is-active {
          background-color: var(--primary-button-outlined-active-background-color);
          border-color: var(--primary-button-active-border-color);
          outline: var(--primary-button-active-border-color);
          color: var(--primary-button-outlined-active-text-color);
        }
        &.is-loading::before {
          border-color: var(--primary-button-outlined-text-color);
        }
      }

      &.is-text {
        color: var(--primary-button-active-border-color) !important;
        box-shadow: none;
        border-color: transparent;
        background-color: transparent;
      }
    }

    &.has-text-primary {
      color: var(--primary-button-text-color) !important;
      background-color: var(--primary-button-background-color);
      text-decoration-color: var(--primary-button-text-color) !important;

      &.is-hovered,
      &:hover,
      &:focus,
      &:focus-visible,
      &.is-focused {
        border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
        box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
        outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
      }

      &:focus,
      &:focus-visible,
      &.is-focused {
        border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
        box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
        outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
      }

      &:active,
      &.is-active {
        background-color: var(--primary-button-active-background-color);
        border-color: var(--primary-button-outlined-active-border-color) !important;
        outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
      }

      &.is-loading::before {
        border-color: var(--primary-button-text-color);
      }

      &.is-outlined {
        background-color: var(--primary-button-outlined-background-color);
        border-color: var(--primary-button-outlined-border-color);
        outline: var(--primary-button-outlined-border-color);
        color: var(--primary-button-outlined-text-color) !important;
        &.is-hovered,
        &:hover,
        &:focus,
        &.is-focused {
          color: var(--primary-button-outlined-hover-focus-text-color) !important;
          border-color: var(--primary-button-outlined-hover-focus-border-color);
          outline: var(--primary-button-outlined-hover-focus-border-color);
          box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
        }
        &:active,
        &.is-active {
          background-color: var(--primary-button-outlined-active-background-color);
          border-color: var(--primary-button-active-border-color);
          outline: var(--primary-button-active-border-color);
          color: var(--primary-button-outlined-active-text-color);
        }
        &.is-loading::before {
          border-color: var(--primary-button-outlined-text-color);
        }
      }

      &.is-text {
        color: var(--primary-button-outlined-text-color) !important;
        box-shadow: none;
        border-color: transparent !important;
        background-color: transparent;
      }
    }

    &.has-text-danger {
      color: var(--primary-button-text-color);
      background-color: var(--primary-button-background-color);

      &.is-hovered,
      &:hover,
      &:focus,
      &.is-focused {
        border-color: var(--primary-button-outlined-hover-focus-border-color);
        color: var(--primary-button-hover-focus-text-color) !important;
      }

      &:focus,
      &:focus-visible,
      &.is-focused {
        color: var(--primary-button-hover-focus-text-color) !important;
      }

      &:active,
      &.is-active {
        background-color: var(--primary-button-active-background-color);
        border-color: var(--primary-button-active-border-color);
        outline: var(--primary-button-active-border-color);
        color: var(--primary-button-active-text-color);
      }

      &.is-loading::before {
        border-color: var(--primary-button-text-color);
      }
      &.is-outlined {
        background-color: inherit;
        border-color: var(--primary-button-outlined-hover-focus-border-color);
        outline: var(--primary-button-outlined-border-color);
        color: var(--primary-button-active-border-color);
        &.is-hovered,
        &:hover,
        &:focus,
        &.is-focused {
          color: var(--primary-button-text-color);
          border-color: var(--primary-button-outlined-hover-focus-border-color);
          outline: var(--primary-button-outlined-hover-focus-border-color);
          box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
          background-color: var(--primary-button-hover-focus-background-color);
        }
        &:active,
        &.is-active {
          background-color: var(--primary-button-outlined-active-background-color);
          border-color: var(--primary-button-active-border-color);
          outline: var(--primary-button-active-border-color);
          color: var(--primary-button-outlined-active-text-color);
        }
        &.is-loading::before {
          border-color: var(--primary-button-outlined-text-color);
        }
      }

      &.is-text {
        color: var(--primary-button-active-border-color) !important;
        box-shadow: none;
        border-color: transparent !important;
        background-color: transparent;
      }
    }

    &.is-chip {
      &.is-hovered,
      &:hover,
      &:focus,
      &.is-focused,
      &.is-active,
      &:active,
      &.is-applied {
        color: var(--primary-button-active-border-color);
        border-color: var(--primary-button-active-border-color) !important;
      }

      &:focus,
      &.is-focused,
      &.is-active,
      &:active,
      &.is-applied {
        background-color: var(--primary-button-chip-active-background-color);

        &.is-active {
          box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
          outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
        }
      }
    }

    &.additional-filter-button,
    &.button-clear-filter {
      color: var(--primary-button-outlined-text-color);
      text-decoration-color: var(--primary-button-text-color);
      box-shadow: none;
    }

    &.icon {
      color: var(--primary-button-outlined-text-color);
      border-color: transparent;
      box-shadow: none;
    }

    &.is-small {
      color: var(--primary-button-outlined-text-color);
    }
  }

  .button[disabled]:not(.is-static):not(.close-detail) {
    &:not(.is-chip) {
      color: var(--primary-button-outlined-text-color);
    }

    &:focus,
    &.is-focused {
      border-color: var(--primary-button-outlined-hover-focus-border-color);
      outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
    }

    &:active,
    &.is-active {
      background-color: var(--primary-button-outlined-active-background-color);
      border-color: var(--primary-button-outlined-active-border-color);
      outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
    }

    &.is-loading::before {
      border-color: var(--primary-button-outlined-text-color);
    }

    &.is-text {
      color: var(--primary-button-outlined-text-color);
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
    }

    &.is-primary {
      color: var(--primary-button-text-color);
      background-color: var(--primary-button-background-color);

      &:active,
      &.is-active {
        background-color: var(--primary-button-active-background-color);
        border-color: var(--primary-button-active-border-color);
        outline: var(--primary-button-active-border-color);
        color: var(--primary-button-active-text-color);
      }

      &.is-loading::before {
        border-color: var(--primary-button-text-color);
      }

      &.is-outlined {
        background-color: var(--primary-button-outlined-background-color);
        border-color: var(--primary-button-outlined-border-color);
        outline: var(--primary-button-outlined-border-color);
        color: var(--primary-button-outlined-text-color);
        &:active,
        &.is-active {
          background-color: var(--primary-button-outlined-active-background-color);
          border-color: var(--primary-button-active-border-color);
          outline: var(--primary-button-active-border-color);
          color: var(--primary-button-outlined-active-text-color);
        }
        &.is-loading::before {
          border-color: var(--primary-button-outlined-text-color);
        }
      }

      &.is-text {
        color: var(--primary-button-outlined-text-color);
        box-shadow: none;
        border-color: transparent;
        background-color: transparent;
      }
    }

    &.is-danger {
      color: var(--primary-button-text-color);
      background-color: var(--primary-button-background-color);

      &.is-hovered,
      &:hover,
      &:focus,
      &.is-focused {
        color: var(--primary-button-hover-focus-text-color);
        background-color: var(--primary-button-hover-focus-background-color);
      }

      &:focus,
      &:focus-visible,
      &.is-focused {
        border-color: var(--primary-button-focus-border-color) !important;
        outline: var(--primary-button-focus-border-color) !important;
      }

      &:active,
      &.is-active {
        background-color: var(--primary-button-active-background-color);
        border-color: var(--primary-button-active-border-color);
        outline: var(--primary-button-active-border-color);
        color: var(--primary-button-active-text-color);
      }

      &.is-loading::before {
        border-color: var(--primary-button-text-color);
      }

      &.is-outlined {
        background-color: inherit;
        border-color: var(--primary-button-outlined-hover-focus-border-color);
        outline: var(--primary-button-outlined-border-color);
        color: var(--primary-button-active-border-color);
        &.is-hovered,
        &:hover,
        &:focus,
        &.is-focused {
          color: var(--primary-button-text-color);

          border-color: var(--primary-button-outlined-hover-focus-border-color);
          outline: var(--primary-button-outlined-hover-focus-border-color);
          box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
          background-color: var(--primary-button-hover-focus-background-color);
        }
        &:active,
        &.is-active {
          background-color: var(--primary-button-outlined-active-background-color);
          border-color: var(--primary-button-active-border-color);
          outline: var(--primary-button-active-border-color);
          color: var(--primary-button-outlined-active-text-color);
        }
        &.is-loading::before {
          border-color: var(--primary-button-outlined-text-color);
        }
      }

      &.is-text {
        color: var(--primary-button-active-border-color) !important;
        box-shadow: none;
        border-color: transparent;
        background-color: transparent;
      }
    }

    &.has-text-primary {
      color: var(--primary-button-text-color) !important;
      background-color: var(--primary-button-background-color);
      text-decoration-color: var(--primary-button-text-color) !important;

      &.is-hovered,
      &:hover,
      &:focus,
      &:focus-visible,
      &.is-focused {
        border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
        box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
        outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
      }

      &:focus,
      &:focus-visible,
      &.is-focused {
        border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
        box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color) !important;
        outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
      }

      &:active,
      &.is-active {
        background-color: var(--primary-button-active-background-color);
        border-color: var(--primary-button-outlined-active-border-color) !important;
        outline: 1px solid var(--primary-button-outlined-hover-focus-border-color) !important;
      }

      &.is-loading::before {
        border-color: var(--primary-button-text-color);
      }

      &.is-outlined {
        background-color: var(--primary-button-outlined-background-color);
        border-color: var(--primary-button-outlined-border-color);
        outline: var(--primary-button-outlined-border-color);
        color: var(--primary-button-outlined-text-color) !important;
        &.is-hovered,
        &:hover,
        &:focus,
        &.is-focused {
          color: var(--primary-button-outlined-hover-focus-text-color) !important;
          border-color: var(--primary-button-outlined-hover-focus-border-color);
          outline: var(--primary-button-outlined-hover-focus-border-color);
          box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
        }
        &:active,
        &.is-active {
          background-color: var(--primary-button-outlined-active-background-color);
          border-color: var(--primary-button-active-border-color);
          outline: var(--primary-button-active-border-color);
          color: var(--primary-button-outlined-active-text-color);
        }
        &.is-loading::before {
          border-color: var(--primary-button-outlined-text-color);
        }
      }

      &.is-text {
        color: var(--primary-button-outlined-text-color) !important;
        box-shadow: none;
        border-color: transparent !important;
        background-color: transparent;
      }
    }

    &.has-text-danger {
      color: var(--primary-button-text-color);
      background-color: var(--primary-button-background-color);

      &.is-hovered,
      &:hover,
      &:focus,
      &.is-focused {
        border-color: var(--primary-button-outlined-hover-focus-border-color);
      }

      &:focus,
      &:focus-visible,
      &.is-focused {
        color: var(--primary-button-hover-focus-text-color) !important;
      }

      &:active,
      &.is-active {
        background-color: var(--primary-button-active-background-color);
        border-color: var(--primary-button-active-border-color);
        outline: var(--primary-button-active-border-color);
        color: var(--primary-button-active-text-color);
      }

      &.is-loading::before {
        border-color: var(--primary-button-text-color);
      }
      &.is-outlined {
        background-color: inherit;
        border-color: var(--primary-button-outlined-hover-focus-border-color);
        outline: var(--primary-button-outlined-border-color);
        color: var(--primary-button-active-border-color);
        &.is-hovered,
        &:hover,
        &:focus,
        &.is-focused {
          color: var(--primary-button-text-color);
          border-color: var(--primary-button-outlined-hover-focus-border-color);
          outline: var(--primary-button-outlined-hover-focus-border-color);
          box-shadow: 0 0 0 0.125em var(--primary-button-focus-box-shadow-color);
          background-color: var(--primary-button-hover-focus-background-color);
        }
        &:active,
        &.is-active {
          background-color: var(--primary-button-outlined-active-background-color);
          border-color: var(--primary-button-active-border-color);
          outline: var(--primary-button-active-border-color);
          color: var(--primary-button-outlined-active-text-color);
        }
        &.is-loading::before {
          border-color: var(--primary-button-outlined-text-color);
        }
      }

      &.is-text {
        color: var(--primary-button-active-border-color) !important;
        box-shadow: none;
        border-color: transparent !important;
        background-color: transparent;
      }
    }

    &.is-chip {
      &.is-active,
      &:active,
      &.is-applied {
        color: var(--primary-button-active-border-color);
        border-color: var(--primary-button-active-border-color) !important;
      }

      &.is-active,
      &:active,
      &.is-applied {
        background-color: var(--primary-button-chip-active-background-color);

        &.is-active {
          outline: 1px solid var(--primary-button-outlined-hover-focus-border-color);
        }
      }
    }

    &.additional-filter-button,
    &.button-clear-filter {
      color: var(--primary-button-outlined-text-color);
      text-decoration-color: var(--primary-button-text-color);
      box-shadow: none;
    }

    &.icon {
      color: var(--primary-button-outlined-text-color);
      border-color: transparent;
      box-shadow: none;
    }

    &.is-small {
      color: var(--primary-button-outlined-text-color);
    }
  }

  .button.is-transparent {
    color: var(--primary-button-text-color);
  }
  .button.is-transparent.close-detail {
    color: var(--primary-button-active-border-color) !important;
  }

  .button.is-transparent.close-detail:hover,
  .button:hover {
    outline: 1px solid var(--primary-button-active-border-color) !important;
    color: var(--primary-button-outlined-text-color);
  }

  vui-kpi .button:hover {
    box-shadow: none !important;
  }

  .mat-chip-list mat-chip.mat-chip {
    color: var(--primary-button-active-border-color);
    border-color: var(--primary-button-active-border-color);
    background-color: var(--primary-button-chip-active-background-color);
  }

  // dropdown
  .dropdown-content {
    border-color: var(--dropdown-content-border-color);
    background-color: var(--dropdown-content-background-color);
    box-shadow: 0px 4px 14px var(--dropdown-content-box-shadow-color);
  }

  .dropdown-menu.add-filter-menu a.dropdown-item {
    &:focus {
      background-color: var(--dropdown-item-filter-focus-background-color);
    }
  }

  a.dropdown-item {
    color: var(--dropdown-item-color);

    &:hover,
    &:focus,
    &:active {
      color: var(--dropdown-item-color);
      background-color: var(--dropdown-item-hover-background-color);
    }

    &.is-active {
      color: var(--dropdown-item-active-color) !important;
      background-color: var(--dropdown-item-active-background-color) !important;
    }
  }

  mat-tree .tree-item,
  mat-tree mat-tree-node {
    &:hover {
      background-color: var(--dropdown-item-hover-background-color);
    }
  }

  // input
  .input:active,
  .input:focus,
  .is-active.input,
  .is-active.textarea,
  .is-focused.input,
  .is-focused.textarea,
  .select select.is-active,
  .select select.is-focused,
  .select select:active,
  .select select:focus,
  .textarea:active,
  .textarea:focus {
    border-color: var(--control-active-focus-border-color) !important;
    box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color);
  }

  input[type='radio']:checked + span {
    &::before {
      border-color: var(--control-active-focus-border-color) !important;
    }

    &::after {
      background-color: var(--control-active-focus-border-color) !important;
    }
  }

  input[type='radio']:focus + span {
    &::before {
      border-color: var(--control-active-focus-border-color) !important;
      box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color) !important;
    }

    &::after {
      background-color: var(--control-active-focus-border-color) !important;
    }
  }

  input[type='checkbox']:checked + span,
  input[type='checkbox']:checked + label,
  input[type='checkbox']:indeterminate + span,
  input[type='checkbox']:indeterminate + label {
    &::before {
      border-color: var(--control-active-focus-border-color) !important;
      background-color: var(--control-active-focus-border-color) !important;
    }
  }

  input[type='checkbox']:focus + span,
  input[type='checkbox']:focus + label {
    &::before {
      outline: 1px solid var(--control-active-focus-border-color) !important;
      border-color: var(--control-active-focus-border-color) !important;
    }
  }

  input[type='text'].copy-input {
    color: var(--primary-button-outlined-text-color) !important;
  }

  // links
  a,
  a:hover,
  a:focus,
  a:visited {
    color: var(--link-color) !important;
  }
  a.has-text-dark {
    color: var(--navbar-item-color) !important;
  }

  i.has-text-link {
    border-color: var(--link-color);
  }

  .has-text-link,
  .has-text-link:hover,
  .has-text-link:active {
    color: var(--link-color) !important;
  }

  // steps
  .steps:not(.is-hollow) {
    .steps-marker:not(.is-hollow),
    .steps-segment::after,
    .steps-segment.is-active .steps-marker:not(.is-hollow) {
      background-color: var(--step-background-color) !important;
    }

    .steps-segment.is-active ~ .steps-segment::after,
    .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow),
    .steps-segment.is-active::after {
      background-color: var(--step-default-background-color) !important;
    }
  }

  // ng-select
  .ng-dropdown-panel .ng-dropdown-panel-items {
    & .ng-option.ng-option-marked,
    & .ng-option.ng-option-selected {
      background-color: var(--ng-select-background-color) !important;
    }
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: var(--ng-select-background-color);

    & .ng-value-icon.left {
      border-right-color: var(--ng-select-tag-border-color);

      &:hover {
        background-color: var(--ng-select-background-color);
      }
    }
  }

  .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: var(--control-active-focus-border-color);
    box-shadow: 0 0 0 0.125em var(--control-active-focus-box-shadow-color);
  }

  // ng-datepicker
  ngb-datepicker {
    .ngb-dp-arrow-btn {
      color: var(--ng-datepicker-weekdays-arrows);
    }
    .ngb-dp-weekdays .ngb-dp-weekday {
      color: var(--brand-background);
    }
    .disabled .custom-day {
      color: var(--brand-background);
      opacity: 0.3;
    }

    .custom-day {
      &:hover,
      &.is-hovered,
      &.first.range,
      &.last.range {
        background-color: var(--ng-datepicker-custom-day-background-color);
      }

      &.faded {
        background-color: var(--ng-datepicker-range-background-color);
      }
    }
  }

  // tabs
  .tabs li.is-active a,
  .sidepanel.sidepanel--theme-winter .tabs li.is-active a {
    color: var(--tab-color);
    border-bottom-color: var(--tab-border-color);
  }

  // table
  .table {
    &.is-hoverable {
      tbody {
        > tr:not(.is-selected):focus-within,
        > tr:not(.is-selected):hover {
          background-color: var(--table-background-color);
        }
      }
    }
  }

  // tree-component
  .node-content-wrapper {
    &:hover {
      box-shadow: none;
      background-color: var(--tree-wrapper-hover-background-color);
    }

    &.node-content-wrapper-focused {
      &:hover,
      & {
        box-shadow: none;
        background-color: var(--tree-wrapper-active-background-color);
      }
    }
  }

  tree-root .node-content-wrapper-active {
    background: none;
    box-shadow: none;
  }
  
  tree-root .node-content-wrapper-active:hover {
    background: none;
  }

  tree-node-wrapper {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      border: 1px solid var(--brand-outlined-border);
      color: $vf-white-100;

      &:disabled {
        border-color: $vf-black-20;
        opacity: 1;
        cursor: default;
      }
      &:focus {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        border-color: var(--control-active-focus-border-color);
        outline: 1px solid var(--control-active-focus-border-color);
      }

      &:indeterminate:after {
        background-color: var(--control-active-focus-border-color);
      }

      &:checked:before {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border-color: var(--control-active-focus-border-color) !important;
        background-color: var(--control-active-focus-border-color) !important;
      }

      &:checked:after {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-color: var(--control-active-focus-border-color);
        border-color: var(--brand-outlined-border);
      }
    }
  }

  .load-more-container .spinner-container .spinner::before {
    border: 0.25rem solid var(--control-active-focus-border-color);
    border-top-color: var(--control-active-focus-border-color);
    border-top-style: solid;
    border-top-width: 0.25rem;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 0.25rem;
    border-bottom-color: var(--control-active-focus-border-color);
    border-bottom-style: solid;
    border-bottom-width: 0.25rem;
    border-left-color: var(--control-active-focus-border-color);
    border-left-style: solid;
    border-left-width: 0.25rem;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
  }

  footer {
    display: none;
  }

  .notification.is-info {
    background-color: var(--brand-hover-light-background);
    border-color: var(--primary-button-outlined-hover-focus-border-color);
    color: $vf-black-85 !important;
  }

  // Home Product Card
  .home-product-card {
    .home-product-card-right {
      .no-kpi-background {
        border: 1px solid var(--home-card-kpi-border-color);
        border-radius: 32px;
      }
    }
  }
  .has-background-highlighted {
    background-color: var(--brand-hover-light-background);
    border-color: var(--primary-button-outlined-hover-focus-border-color);
  }
}

.button.is-text:active,
.button.is-text:focus,
.button.is-text:hover,
.button.is-text:focus-visible {
  border-color: var(--primary-button-outlined-hover-focus-border-color);
}

.button.is-primary.is-outlined[disabled],
fieldset[disabled] .button.is-primary.is-outlined {
  border-color: var(--primary-button-outlined-hover-focus-border-color) !important;
  color: var(--primary-button-outlined-hover-focus-border-color) !important;
}

@media screen and (max-width: 1023px) {
  .white-labeled {
    .hero.is-rebrand {
      background: 0 !important;
      height: auto;
      margin-bottom: inherit;
      top: inherit;
      max-height: inherit;
      padding-top: inherit;

      .hero-body {
        padding: 1.5rem 0 0 1.5rem;

        .title {
          font-size: 2rem;
        }
      }
    }
  }
}
