[dir='rtl'] {
  portal-date-control,
  portal-date-picker {
    .ngb-dp-arrow-btn {
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
      -webkit-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }
}
